import { Box, Button } from "@mui/material";
import { queryGetProprietaryTefAccounts } from "api/pixLimitValidator/pixLimitValidator";
import { useQueryGetUserInfo } from "api/security-api";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import getSafe from "helpers/getSafe";
import { useCustomQuery } from "helpers/useCustomQuery";
import { useInterval } from "helpers/useInterval";
import { useCallback, useEffect, useState } from "react";
import { checkPermission } from "services/interfaceController";
import AccountCard from "./components/AccountCard";
import TedTransferDialog from "./components/TransferDialog";

const ACCOUNTS_INTERVAL_FETCH = 1000;

const PixLimitValidatorAccounts = (props) => {
    const userRoles = JSON.parse(sessionStorage.getItem("BAAS_USER_ROLES")) || [];
    const hasTransferPermission = userRoles.includes("pix-limit-valid-v2-w") || userRoles.includes("tef-proprietary-w");
    const { data: userInfo } = useQueryGetUserInfo();
    const [transferOpen, setTransferOpen] = useState(false);
    const [errorsCount, setErrorsCount] = useState(0);
    const [refetchAccounts, setRefetchAccounts] = useState(false);

    const handleRefetchAccounts = useCallback(() => {
        setRefetchAccounts(!refetchAccounts);
    }, [refetchAccounts]);

    const {
        fetch: loadAccountsData,
        loading: loadingAccounts,
        data: accountsData,
    } = useCustomQuery(queryGetProprietaryTefAccounts);

    useEffect(() => {
        if (hasTransferPermission) {
            loadAccountsData({
                onError: () => {
                    setErrorsCount(errorsCount + 1);
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchAccounts]);

    useInterval(() => {
        if (errorsCount < 3 && !transferOpen && !loadingAccounts) {
            loadAccountsData({
                onError: () => {
                    setErrorsCount(errorsCount + 1);
                },
            });
        }
    }, ACCOUNTS_INTERVAL_FETCH);

    const internalAccounts = accountsData.filter((acc) => {
        return acc.type !== "ExternalAccount"
    });

    const externalAccounts = accountsData.filter((acc) => {
        return acc.type === "ExternalAccount"
    });

    return (
        <BaasFlex flexDirection="column" width="100%">
            <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
            <BaasContentGrid spacing={1}>
                <BaasRow container spacing={1}>
                    <BaasText sx={{ paddingLeft: "8px" }} variant="h6">
                        Hello, {getSafe(userInfo, "fullName")}
                    </BaasText>
                </BaasRow>
                {hasTransferPermission && accountsData?.length > 0 && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        mb={2}
                    >
                        <BaasText
                            sx={{ marginBottom: "8px", marginTop: "16px" }}
                            color="textSecondary"
                        >
                            Transfer between accounts
                        </BaasText>
                        <Box display="flex">
                            <Button
                                variant="contained"
                                onClick={() => setTransferOpen(true)}
                                sx={{ width: "120px" }}
                            >
                                Transfer
                            </Button>
                        </Box>
                    </Box>
                )}
                {hasTransferPermission ? (
                    <>
                        <BaasGrid p="4px 0px" item>
                            <BaasGrid display="flex">
                                <BaasText variant="h6">Internal Accounts</BaasText>
                            </BaasGrid>
                        </BaasGrid>
                        <BaasGrid p="4px 0px" mb={1} item>
                            <BaasGrid display="flex">
                                <BaasText variant="body1" color="textSecondary">This list displays your internal payment service provider (PSP) accounts.</BaasText>
                            </BaasGrid>
                        </BaasGrid>
                        {loadingAccounts && !internalAccounts.length ? (
                            <Box
                                display="flex"
                                flexDirection="row"
                                marginBottom="8px"
                            >
                                <BaasSkeleton
                                    variant="rectangular"
                                    width="242px"
                                    height="152px"
                                    sx={{ borderRadius: "8px", marginRight: "8px" }}
                                />
                                <BaasSkeleton
                                    variant="rectangular"
                                    width="242px"
                                    height="152px"
                                    sx={{ borderRadius: "8px" }}
                                />
                            </Box>
                        ) : (
                            <>
                                {internalAccounts.length > 0 ? (
                                    <>
                                        {checkPermission([
                                            "pix-limit-valid-v2-r",
                                            "pix-limit-valid-v2-w",
                                            "all-w",
                                            "all-r",
                                            "tef-proprietary-w",
                                        ]) && (
                                                <>
                                                    <BaasFlex
                                                        style={{
                                                            flexWrap: "wrap",
                                                            gap: 2,
                                                            maxWidth: "75vw",
                                                            marginLeft: "-4px",
                                                            paddingBottom: "8px",
                                                        }}
                                                    >
                                                        {(internalAccounts || []).map((account, index) => (
                                                            <AccountCard
                                                                key={`${account}_${index}`}
                                                                account={account}
                                                                index={index}
                                                            />
                                                        ))}
                                                    </BaasFlex>
                                                </>
                                            )}
                                    </>
                                ) : (
                                    <BaasText
                                        sx={{ marginBottom: "16px" }}
                                        color="textSecondary"
                                    >
                                        No accounts found...
                                    </BaasText>
                                )}
                            </>
                        )}
                        {externalAccounts.length > 0 && (
                            <>
                                <BaasGrid p="8px 0px" item>
                                    <BaasGrid display="flex">
                                        <BaasText variant="h6">External PSP Accounts</BaasText>
                                    </BaasGrid>
                                </BaasGrid>
                                <BaasGrid p="4px 0px" mb={1} item>
                                    <BaasGrid display="flex">
                                        <BaasText variant="body1" color="textSecondary">This list displays your external payment service provider (PSP) accounts. Since these are external accounts, the balance is not visible.</BaasText>
                                    </BaasGrid>
                                </BaasGrid>
                                {loadingAccounts && !externalAccounts.length ? (
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        marginBottom="8px"
                                    >
                                        <BaasSkeleton
                                            variant="rectangular"
                                            width="242px"
                                            height="152px"
                                            sx={{ borderRadius: "8px", marginRight: "8px" }}
                                        />
                                        <BaasSkeleton
                                            variant="rectangular"
                                            width="242px"
                                            height="152px"
                                            sx={{ borderRadius: "8px" }}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        {externalAccounts.length > 0 ? (
                                            <>
                                                {checkPermission([
                                                    "pix-limit-valid-v2-r",
                                                    "pix-limit-valid-v2-w",
                                                    "all-w",
                                                    "tef-proprietary-w",
                                                    "all-r",
                                                ]) && (
                                                        <>
                                                            <BaasFlex
                                                                style={{
                                                                    flexWrap: "wrap",
                                                                    gap: 2,
                                                                    maxWidth: "75vw",
                                                                    marginLeft: "-4px",
                                                                    paddingBottom: "8px",
                                                                }}
                                                            >
                                                                {(externalAccounts || []).map((account, index) => (
                                                                    <AccountCard
                                                                        key={`${account}_${index}`}
                                                                        account={account}
                                                                        index={index}
                                                                    />
                                                                ))}
                                                            </BaasFlex>
                                                        </>
                                                    )}
                                            </>
                                        ) : (
                                            <BaasText
                                                sx={{ marginBottom: "16px" }}
                                                color="textSecondary"
                                            >
                                                No accounts found...
                                            </BaasText>
                                        )}
                                    </>)}
                            </>
                        )}
                    </>
                ) : null}
            </BaasContentGrid>
            {transferOpen && (
                <TedTransferDialog
                    open={transferOpen}
                    accounts={accountsData}
                    setOpen={setTransferOpen}
                    handleRefetchAccounts={handleRefetchAccounts}
                />
            )}
        </BaasFlex>
    );
};
export default PixLimitValidatorAccounts;
