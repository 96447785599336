import { Skeleton } from "@mui/material";
import { mutationSimulateCashIn } from "api/baasTester/cashInSimulator-api";
import { useQueryGetAccountInfo } from "api/security-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { Form, Formik } from "formik";
import getSafe from "helpers/getSafe";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCallback } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { toastMessages } from "constants/toastMessages";

const CashInSimulator = () => {
    const { data, isLoading } = useQueryGetAccountInfo();
    const { mutate: mutateCashInPayment, isSubmitting } = useCustomMutation(mutationSimulateCashIn);

    const validationSchema = yup.object().shape({
        selectedAccount: yup.object().required("Selected Account is required"),
        amount: yup.number().min(0.01, "Min amount is R$ 00,01").required("Amount is a required field"),
    });

    const onSubmitPayment = useCallback((values) => {
        const payload = {
            branch: values.selectedAccount.branch,
            account: values.selectedAccount.account,
            amount: values.amount * 100
        }
        mutateCashInPayment(
            {
                ...payload,
            },
        );
    }, []);

    return (
        <BaasFlex flex={1} flexDirection="column">
            <BaasPageHeader>Test Interface</BaasPageHeader>
            <BaasGrid
                item
                spacing={1}
                style={{ padding: "16px", marginBottom: "16px" }}
            >
                <BaasRow>
                    <BaasText variant="h6">Transfer Simulator</BaasText>
                </BaasRow>
                <BaasText variant="body2">
                    Simulates a TEF cash in for a given account.
                </BaasText>
            </BaasGrid>
            <BaasContentGrid spacing={4}>
                <StackCard>
                    {isLoading ? (<Skeleton />) : (<>{getSafe(data, "body.items", []).length > 0 ? (
                        <BaasGrid item xs={12}>
                            <Formik
                                initialValues={{
                                    selectedAccount: getSafe(data, "body.items[0]"),
                                    amount: 0
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => onSubmitPayment(values)}
                            >
                                {({ values, errors }) => (
                                    <Form>
                                        {console.log(errors)}
                                        <BaasGrid container p={1} spacing={3}>
                                            <BaasGrid item sm={6}>
                                                <BaasTextField
                                                    fullWidth
                                                    name="selectedAccount"
                                                    label="Selected Account"
                                                    select
                                                    errors={errors}
                                                    error={errors.selectedAccount}
                                                >
                                                    {getSafe(data, "body.items", []).map((account) => (
                                                        <BaasMenuItem
                                                            value={account}
                                                        >{`${account.bank} | ${account.branch} | ${account.account}`}</BaasMenuItem>
                                                    ))}
                                                </BaasTextField>
                                            </BaasGrid>
                                            <BaasGrid item sm={6}>
                                                <BaasTextFieldCurrency
                                                    fullWidth
                                                    errors={errors}
                                                    error={errors.amount}
                                                    required
                                                    name="amount"
                                                    label="Amount"
                                                />
                                            </BaasGrid>
                                        </BaasGrid>
                                        <BaasGrid item sm={12}>
                                            <BaasButton
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                submitDisabled={!values.amount}
                                                loading={isSubmitting}
                                            >
                                                Transfer
                                            </BaasButton>
                                        </BaasGrid>
                                    </Form>
                                )}
                            </Formik>
                        </BaasGrid>
                    ) : null}
                    </>
                    )}
                </StackCard>
            </BaasContentGrid>
        </BaasFlex >
    );
};

export default CashInSimulator;
