import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { formatDateTime, formatStatusColor } from "../utils/format";

const AccountCardStyle = styled.div`
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 4px 0 rgba(50, 50, 50, 0.2);
  }
`;

export default function AccountCard({ account }) {
  const [details, setDetails] = useState({});
  const history = useHistory();
  const theme = useTheme();

  const enrollmentDateTime = useMemo(() => {
    return formatDateTime(details.dateTime);
  }, [details]);

  const statusColor = useMemo(() => {
    return formatStatusColor(details.status);
  }, [details]);

  const navigateToPage = () => {
    if (details.pactualId) {
      history.push(
        `/openFinance/enrolled-accounts?pactualId=${details.pactualId}`
      );
    }
  };

  useEffect(() => {
    setDetails({
      pactualId: account?.pactualId,
      status: account?.body?.data?.status || account?.status,
      dateTime:
        account?.body?.data?.statusUpdateDateTime ||
        account?.body?.data?.creationDateTime,
      enrollmentName: account?.body?.data?.enrollmentName,
    });
  }, [account]);

  return (
    <AccountCardStyle onClick={navigateToPage}>
      <StackCard>
        <Box display="flex" justifyContent="space-between">
          <BaasText color={statusColor}>{details.status}</BaasText>
          <BaasText>{enrollmentDateTime}</BaasText>
        </Box>
        <BaasText variant="h6" sx={{ color: theme.palette.grey[600] }}>
          {details.enrollmentName || "N/A"}
        </BaasText>
      </StackCard>
    </AccountCardStyle>
  );
}
