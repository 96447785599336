import { queryGetBankOrganizations } from "api/jsr/jsr-api";
import {
  queryGetEnrolledAccounts,
  queryGetLoggedUserCpf,
} from "api/openFinance/enrolledAccounts-api";
import { useCustomQuery } from "helpers/useCustomQuery";
import { createContext, useContext, useEffect } from "react";

const EnrolledAccountsContext = createContext();

export const useEnrolledAccountsContext = () =>
  useContext(EnrolledAccountsContext);

export const EnrolledAccountsProvider = ({ children }) => {
  const {
    fetch: loadBanks,
    loading: loadingBanks,
    data: banksData,
  } = useCustomQuery(queryGetBankOrganizations);

  const {
    fetch: loadEnrolledAccounts,
    loading: loadingEnrolledAccounts,
    data: enrolledAccountsData,
  } = useCustomQuery(queryGetEnrolledAccounts);

  const {
    fetch: loadLoggedUserCpf,
    loading: loadingLoggedUserCpf,
    data: loggedUserCpfData,
  } = useCustomQuery(queryGetLoggedUserCpf);

  useEffect(() => {
    loadLoggedUserCpf({});
    loadBanks({});
  }, []);

  useEffect(() => {
    if (loggedUserCpfData) {
      loadEnrolledAccounts({
        values: { cpf: loggedUserCpfData.cpf },
      });
    }
  }, [loggedUserCpfData]);

  return (
    <EnrolledAccountsContext.Provider
      value={{
        banksData,
        enrolledAccountsData,
        loadEnrolledAccounts,
        loadingBanks,
        loadingEnrolledAccounts,
        loadingLoggedUserCpf,
        loggedUserCpfData,
      }}
    >
      {children}
    </EnrolledAccountsContext.Provider>
  );
};
