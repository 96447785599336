import { baasApiCall } from "services/apiCall";

export const mutationUpdateEnrolledAccount = (pactualId, payload) => {
  return baasApiCall("PATCH", `/bk/mock/enrollment/${pactualId}`, payload)
    .then((data) => data)
    .catch((error) => error);
};

export const queryGetEnrolledAccounts = ({ cpf }) => {
  return baasApiCall(
    "GET",
    `/bk/mock/enrollment/logged-user-identification/${cpf}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryGetLoggedUserCpf = () => {
  return baasApiCall("GET", `/bk/mock/enrollment/credential`)
    .then((data) => data.json())
    .catch((error) => error.json());
};
