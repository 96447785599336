import { Box, CircularProgress } from "@mui/material";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasModal from "components/atoms/Modal/Modal";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasLoading from "components/molecules/Loading/Loading";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useEnrolledAccountsContext } from "./contexts/EnrolledAccountsContext";
import { formatBank, formatDateTime } from "./utils/format";
import { mutationUpdateEnrolledAccount } from "api/openFinance/enrolledAccounts-api";

export default function EnrolledAccountsDetails() {
  const [details, setDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pactualId = queryParams.get("pactualId");

  const {
    banksData,
    enrolledAccountsData,
    loadEnrolledAccounts,
    loadingBanks,
    loadingEnrolledAccounts,
    loadingLoggedUserCpf,
    loggedUserCpfData,
  } = useEnrolledAccountsContext();

  const navigateToList = () => {
    history.push("/openFinance/enrolled-accounts");
  };

  const sendRevokeEnrollment = async () => {
    if (loggedUserCpfData && pactualId) {
      const payload = {
        cancelledBy: {
          document: {
            identification: loggedUserCpfData.cpf,
            rel: "CPF",
          },
        },
        revocationReason: "REVOGADO_MANUALMENTE",
        additionalInformation: "User revoked",
      };
      setRevokeLoading(true);
      await mutationUpdateEnrolledAccount(pactualId, payload);
      loadEnrolledAccounts({
        values: { cpf: loggedUserCpfData.cpf },
      });
      setShowModal(false);
      setRevokeLoading(false);
    }
  };

  useEffect(() => {
    if (enrolledAccountsData?.length) {
      const foundEnrollment = enrolledAccountsData.find(
        (enrollment) => enrollment.pactualId === pactualId
      );
      setDetails({
        enrollmentName: {
          label: "Enrollment Name",
          value: foundEnrollment?.body?.data?.enrollmentName,
        },
        bank: {
          label: "Bank",
          value: formatBank(
            foundEnrollment?.body?.authorizationServerId,
            banksData
          ),
        },
        debtorAccountNumber: {
          label: "Account Number",
          value: foundEnrollment?.body?.data?.debtorAccount?.number,
        },
        debtorAccountIssuer: {
          label: "Branch",
          value: foundEnrollment?.body?.data?.debtorAccount?.issuer,
        },
        creationDateTime: {
          label: "Creation Date",
          value: formatDateTime(foundEnrollment?.body?.data?.creationDateTime),
        },
        expirationDateTime: {
          label: "Expiration Date",
          value: formatDateTime(
            foundEnrollment?.body?.data?.expirationDateTime
          ),
        },
        status: {
          label: "Status",
          value: foundEnrollment?.body?.data?.status || foundEnrollment?.status,
        },
      });
    } else {
      setDetails(null);
    }
    // eslint-disable-next-line
  }, [enrolledAccountsData]);

  if (loadingBanks || loadingEnrolledAccounts || loadingLoggedUserCpf) {
    return <BaasLoading />;
  }

  return (
    <>
      <BaasFlex width="100%" flexDirection="column">
        <BaasPageHeader>Open Finance</BaasPageHeader>
        <BaasContentGrid>
          <BaasGrid pb={2} pt={2}>
            <StackCard>
              <BaasText variant="h6" mb={2}>
                Enrolled Account Details
              </BaasText>
              {!details && <BaasText>There are no data available.</BaasText>}
              {details && (
                <BaasGrid container p={1} spacing={3}>
                  {Object.keys(details).map((key) => (
                    <BaasGrid key={key} item xs={12} sm={6}>
                      <BaasInfoDisplay
                        label={details[key].label}
                        value={details[key].value || "-"}
                      />
                    </BaasGrid>
                  ))}
                  {details.status.value === "AUTHORISED" && (
                    <BaasGrid item xs={12} sm={6}>
                      <BaasButton
                        variant="contained"
                        color="error"
                        backgroundColor="black"
                        onClick={() => setShowModal(true)}
                      >
                        Revoke Enrollment
                      </BaasButton>
                    </BaasGrid>
                  )}
                </BaasGrid>
              )}
            </StackCard>
            <BaasGrid mt={2} item xs={12} sm={6}>
              <BaasButton variant="outlined" onClick={navigateToList}>
                Go Back
              </BaasButton>
            </BaasGrid>
          </BaasGrid>
        </BaasContentGrid>
      </BaasFlex>
      <BaasModal open={showModal}>
        <Box
          alignItems="center"
          display="flex"
          height="100vh"
          justifyContent="center"
          width="100vw"
        >
          <StackCard>
            <Box display="flex" flexDirection="column" gap="16px" width="400px">
              <BaasText variant="h6">Revoke Enrollment</BaasText>
              <BaasText>
                The revoke enrollment process is an irreversible action, and
                there is a possibility that you may lose some payment
                functionalities because of it.
              </BaasText>
              <BaasText>Are you sure you want to revoke?</BaasText>
              <Box
                alignItems="center"
                display="flex"
                gap="16px"
                justifyContent="flex-end"
                paddingTop="16px"
              >
                {revokeLoading && (
                  <CircularProgress color="primary" size={40} thickness={4} />
                )}
                <BaasButton
                  variant="outlined"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </BaasButton>
                <BaasButton
                  variant="contained"
                  color="error"
                  backgroundColor="black"
                  onClick={sendRevokeEnrollment}
                >
                  Confirm
                </BaasButton>
              </Box>
            </Box>
          </StackCard>
        </Box>
      </BaasModal>
    </>
  );
}
