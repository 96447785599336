import { queryGetBankOrganizations } from "api/jsr/jsr-api";
import { queryGetLoggedUserCpf } from "api/openFinance/enrolledAccounts-api";
import { queryGetPaymentHistory } from "api/openFinance/paymentHistory-api";
import { useCustomQuery } from "helpers/useCustomQuery";
import { createContext, useContext, useEffect } from "react";

const PaymentHistoryContext = createContext();

export const usePaymentHistoryContext = () => useContext(PaymentHistoryContext);

export const PaymentHistoryProvider = ({ children }) => {
  const {
    fetch: loadBanks,
    loading: loadingBanks,
    data: banksData,
  } = useCustomQuery(queryGetBankOrganizations);

  const {
    fetch: loadPaymentHistory,
    loading: loadingPaymentHistory,
    data: paymentHistoryData,
  } = useCustomQuery(queryGetPaymentHistory);

  const {
    fetch: loadLoggedUserCpf,
    loading: loadingLoggedUserCpf,
    data: loggedUserCpfData,
  } = useCustomQuery(queryGetLoggedUserCpf);

  useEffect(() => {
    loadLoggedUserCpf({});
    loadBanks({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loggedUserCpfData) {
      loadPaymentHistory({
        values: { cpf: loggedUserCpfData.cpf },
      });
    }
    // eslint-disable-next-line
  }, [loggedUserCpfData]);

  return (
    <PaymentHistoryContext.Provider
      value={{
        banksData,
        paymentHistoryData,
        loadingBanks,
        loadingPaymentHistory,
        loadingLoggedUserCpf,
      }}
    >
      {children}
    </PaymentHistoryContext.Provider>
  );
};
