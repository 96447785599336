import { Box, Card, DialogContent, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { ArrowDownward } from "@mui/icons-material";
import { ReactComponent as BtgPactual } from "assets/btg-logo-md.svg";
import { ReactComponent as OpenFinanceLogo } from "assets/open-finance/logo-open-finance.svg";

import { useEffect, useState } from "react";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
export default function RedirectPanel({
  enrollmentBank,
  open,
  setOpen,
  redirectUrl
}) {
  const theme = useTheme();
  const [countdown, setCountdown] = useState(7);

  useEffect(() => {
    if (countdown === 0) {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
      return;
    }
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdown]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => { }}
    >
      <DialogTitle fullScreen style={{ background: theme.palette.primary.dark, color: "white", fontSize: "18px", fontWeight: "normal" }}>
        <Box mt={1} mb={1} width="100%" display="flex" justifyContent="space-between" alignItems="center">
          Redirecting
          <Typography style={{ color: "white", width: "45px", textAlign: "center" }}>{countdown}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={1} width="100%" display="flex" alignItems="center" flexDirection="column">
          <OpenFinanceLogo style={{ width: "150px", height: "120px" }} />
          <Typography mb={1} style={{ color: "GrayText" }}>You are being safely redirected with Open Finance.</Typography>
        </Box>

        <Card sx={{ padding: 1, textAlign: "center" }}>
          <BtgPactual style={{ width: "120px", height: "60px" }} />
          <Box mt={1} mb={1} width="100%" textAlign="center">
            <ArrowDownward style={{ color: "GrayText" }} />
          </Box>
          <Box sx={{ padding: 1, minHeight: "95px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            {enrollmentBank.customerFriendlyLogoUri ? (<img src={enrollmentBank.customerFriendlyLogoUri} style={{ width: "120px", height: "60px" }} />) : (<AccountBalanceIcon style={{ width: "120px", height: "90px", color: theme.palette.primary.dark }} />)}
            <Typography variant="body2" color="GrayText" mt={1}> {enrollmentBank?.customerFriendlyName}</Typography>
          </Box>
        </Card>
        <Typography mt={1} color="GrayText"> We are redirecting you to the selected institution's environment.</Typography>
        <Box mt={1} mb={1} width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="GrayText" >You have 15 minutes to confirm the operation.</Typography>

        </Box>
      </DialogContent>
    </Dialog>
  );
}
