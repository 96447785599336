// import { convertArrayBufferToBase64 } from "helpers/convertArrayBufferToBase64";
import { Box, Button } from "@mui/material";
import { mutationSendLog } from "api/jsr/jsr-api";
import { mutationFidoRegister, queryGetEnrollmentsByPactualId } from "api/jsr/jsr-api";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { toastMessages } from "constants/toastMessages";
import { useCustomQuery } from "helpers/useCustomQuery";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

export const FidoBiometricsPanel = ({ formValues, setFormValues, handleRestartFlow }) => {
    const [resp, setResp] = useState()
    const {
        fetch: getFidoOptions,
        loading: loadingFidoOptions,
        data: fidoOptionsData,
    } = useCustomQuery(queryGetEnrollmentsByPactualId);


    const { mutate: mutateSendLog } = useMutation((e) => mutationSendLog(e));

    useEffect(() => {
        getFidoOptions({
            values: {
                pactualId: formValues.biometricsPactualId,
            },
        });
        const parsedFormValues = JSON.parse(localStorage.getItem("jsrValues"));
        setFormValues({
            ...parsedFormValues,
            isFidoBiometrics: true,
            biometricsPactualId: formValues.biometricsPactualId
        });
    }, []);

    const base64URLDecode = (value) => {
        const base64URLDecodedString = value
            .replaceAll("-", "+")
            .replaceAll("_", "/");
        const padding = base64URLDecodedString.length % 4 === 0 ? '' : '='.repeat(4 - (base64URLDecodedString.length % 4));
        const base64WithPadding = base64URLDecodedString + padding;
        return base64WithPadding;
    }

    const createCredential = async () => {
        const excludeCredentials = fidoOptionsData?.body?.fidoRegistrationOptions?.data?.excludeCredentials ? (fidoOptionsData?.body?.fidoRegistrationOptions?.data?.excludeCredentials || []).map(cred => ({
            type: cred.type,
            id: Uint8Array.from(atob(base64URLDecode(cred.id)), c => c.charCodeAt(0))
        })) : undefined;

        const publicKeyCredentialCreationOptions = {
            rp: {
                name: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.rp?.name,
                id: window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? window.location.hostname : fidoOptionsData?.body?.fidoRegistrationOptions?.data?.rp?.id,
            },
            user: {
                id: Uint8Array.from(atob(fidoOptionsData?.body?.fidoRegistrationOptions?.data?.user?.id), c => c.charCodeAt(0)),
                name: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.user?.name,
                displayName: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.user?.displayName
            },
            challenge: Uint8Array.from(atob(base64URLDecode(fidoOptionsData?.body?.fidoRegistrationOptions?.data?.challenge)), c => c.charCodeAt(0)),
            pubKeyCredParams: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.pubKeyCredParams,
            timeout: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.timeout || undefined,
            excludeCredentials: excludeCredentials,
            authenticatorSelection: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.authenticatorSelection || undefined,
            attestation: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.attestation || undefined,
            attestationFormats: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.attestationFormats || undefined,
            extensions: fidoOptionsData?.body?.fidoRegistrationOptions?.data?.extensions || undefined,
            pactualId: formValues?.biometricsPactualId,
        };
        try {
            const response = await navigator.credentials.create({
                publicKey: publicKeyCredentialCreationOptions
            });
            setResp(response)
            handleSubmitEnrollment(response);
        } catch (e) {
            mutateSendLog(
                {
                    error: `${e.name} + " - " + ${e.message} + " - " + ${e.stack}`,
                    pactualId: formValues?.biometricsPactualId
                },
                {
                    onSuccess: () => { },
                    onError: () => { }
                }
            );
        }
    }



    function arrayBufferToBase64URL(buffer) {
        const uint8Array = new Uint8Array(buffer);
        const binaryString = [...uint8Array.values()]
            .map((byte) => String.fromCodePoint(byte))
            .join("");
        const base64EncodedString = btoa(binaryString);
        const base64URLEncodedString = base64EncodedString
            .replaceAll("+", "-")
            .replaceAll("/", "_")
            .replaceAll("=", "");
        return base64URLEncodedString;
    }

    const handleSubmitEnrollment = useCallback(async (credential) => {
        const fidoAssertionPayload = {
            id: credential.id,
            rawId: arrayBufferToBase64URL(credential.rawId),
            response: {
                clientDataJSON: arrayBufferToBase64URL(credential.response.clientDataJSON),
                attestationObject: arrayBufferToBase64URL(credential.response.attestationObject)
            },
            authenticatorAttachment: credential.authenticatorAttachment,
            type: credential.type,
            clientExtensionResults: credential.getClientExtensionResults()
        }
        const response = await mutationFidoRegister(fidoAssertionPayload, formValues.biometricsPactualId);
        if (response.status < 300) {
            toast.success(toastMessages.requestSuccess);
            if (response.body) {
                setFormValues({
                    ...formValues,
                    isFidoBiometrics: false
                })
            }
        }
        else {
            if ("errors" in response) {
                response.errors.map((error) => toast.error(error.message));
            } else toast.error("Your link is broken");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    return (
        <>
            <BaasGrid item xs={6}>
                <BaasText variant="body2">
                    Fido biometrics enrollment
                </BaasText>
            </BaasGrid>
            <BaasGrid item xs={12}>
                <Box mb={2} mt={3} display="flex" justifyContent="space-between">
                    <Button
                        style={{ minWidth: "150px" }}
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            window.location.replace(`${window.location.origin}${window.location.pathname}`)
                        }}

                    >
                        Restart Flow
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={createCredential}
                        disabled={loadingFidoOptions || !fidoOptionsData}
                    >
                        {loadingFidoOptions ? "Loading Fido Options..." : "Authorize Fido Biometrics Enrollment"}

                    </Button>
                </Box>
            </BaasGrid>

        </>
    );
};
