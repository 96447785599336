// import { convertArrayBufferToBase64 } from "helpers/convertArrayBufferToBase64";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { mutationEnrollment, queryGetBankOrganizations } from "api/jsr/jsr-api";
import AutocompleteDefault from "components/atoms/Autocomplete/AutocompleteDefault";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { getNavigatorInfo } from "helpers/getNavigatorInfo";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import guidGenerator from "services/guidGenerator";
import Endpoints from "constants/endpoints";
import RedirectPanel from "./RedirectPanel";

export const NewEnrollmentPanel = ({ formValues, setFormValues, setIsEnrollingAccount }) => {
    const [enrollmentBank, setSelectedBank] = useState({});
    const [openPanel, setOpenPanel] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState();

    const {
        fetch: loadBanks,
        loading: loadingBanks,
        data: banksData,
    } = useCustomQuery(queryGetBankOrganizations);

    const {
        mutate: mutateEnrollment,
        isSubmitting: isSubmittingEnrollment,
    } = useCustomMutation(mutationEnrollment);

    useEffect(() => {
        loadBanks({});
    }, []);

    const handleSelectBank = (bank) => {
        setSelectedBank(bank)
    }

    const handleSubmitEnrollment = useCallback(() => {
        mutateEnrollment(
            {
                clientRequestId: guidGenerator(),
                authorizationServerId: enrollmentBank.authorizationServerId,
                redirectUrl: Endpoints.Environment === "Production" ? "https://api.developer.btgpactual.com/bk/internal/enrollment/callback" : "https://api-h.developer.btgpactual.com/bk/internal/enrollment/callback",
                permissions: [
                    "PAYMENTS_INITIATE"
                ],
                loggedUser: {
                    "document": {
                        "identification": formValues.taxId,
                        "rel": "CPF"
                    }
                },
                enrollmentName: formValues.enrollmentName,
                riskSignals: getNavigatorInfo(
                    moment("2024-05-23").format("YYYY-MM-DD")
                ),
                metadata: {
                    "platform": "BROWSER",
                    "os": "WINDOWS",
                    "osVersion": "10"
                },
                tags: ["developer-portal"]
            },
            (response) => {
                localStorage.setItem("jsrValues", JSON.stringify(formValues));
                if (response.body.body.redirectAuthorizationUrl) {
                    setOpenPanel(true);
                    setRedirectUrl(response.body.body.redirectAuthorizationUrl)
                }

            },
        );
    }, [enrollmentBank, formValues]);

    const updateField = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <BaasGrid item xs={6}>
                <BaasText variant="body2">
                    New account enrollment
                </BaasText>
            </BaasGrid>
            <>
                <BaasGrid item xs={12}>
                    <Box display="flex" alignItems={"center"} mt="16px">
                        <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
                            Bank organization
                        </BaasText>
                    </Box>
                    <Box display="flex" alignItems={"center"} mt="16px">
                        <AutocompleteDefault
                            name="bank"
                            label={
                                loadingBanks ? "Loading" : "Select a bank"
                            }
                            isLoading={loadingBanks}
                            style={{ width: "100%" }}
                            fullwidth
                            onClear={() => handleSelectBank(null)}
                            onSelect={(value) => {
                                handleSelectBank(value)
                            }
                            }
                            options={banksData || []}
                            getOptionLabel={(option) => `${option.customerFriendlyName || "N/A"}`}
                        />
                    </Box>
                </BaasGrid>
                <BaasGrid item xs={12}>
                    <TextField
                        sx={{ marginTop: 2 }}
                        autoFocus
                        name="enrollmentName"
                        margin="dense"
                        id="name"
                        label={"Enrollment Name"}
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => updateField(e)}
                    />
                </BaasGrid>
                <BaasGrid item xs={12}><Box mb={2} mt={3} display="flex" justifyContent="space-between">
                    <Button
                        style={{ minWidth: "150px" }}
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                            setIsEnrollingAccount(false)}
                    >
                        Cancell Enrollment
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmitEnrollment}
                        disabled={isSubmittingEnrollment || !formValues.enrollmentName.trim() || !enrollmentBank}
                    >
                        {isSubmittingEnrollment ? "Enrolling Account" : "Enroll Account"}
                    </Button>
                </Box>
                </BaasGrid>
                {openPanel && (
                    <RedirectPanel open={Boolean(openPanel)} redirectUrl={redirectUrl} enrollmentBank={enrollmentBank} />
                )}
            </>
        </>
    );
};
