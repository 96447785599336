import { Box, Skeleton } from "@mui/material";
import { useTheme } from "@mui/system";
import { ReactComponent as SuccessIcon } from "assets/icons/BaasTesterLogos/Success.svg";
import BaasText from "components/atoms/Text/BaasText";
import AnswerInput from "components/molecules/AnswerInput/AnswerInput";
import React from "react";
import StackCard from "components/atoms/StackCard/StackCard";

const BaasTesterQuestionsList = ({
  data,
  selectedCategory,
  loading,
  onAnswer,
  submitingAnswer,
}) => {
  const theme = useTheme();

  if (loading) {
    return <Skeleton height="180px" />;
  }

  const sortData = (data || []).sort(
    (a, b) => a.question.order - b.question.order
  );

  const getGroups = () => {
    let options = [];
    sortData.forEach((item) => {
      if (!options.some((opt) => opt === item.question?.group)) {
        options.push(item.question?.group);
      }
    });
    return options.sort((a, b) => a.localeCompare(b));
  };

  const getDataByGroup = (group) => {
    let questionsList = [];
    sortData.forEach((item) => {
      if (item.question.group === group) {
        questionsList.push(item);
      }
    });
    return (questionsList || []).sort(
      (a, b) => a.question.order - b.question.order
    );
  };

  const getQuestionStatus = (status) => {
    if (status === "OBRIGATORY") {
      return "🔴 Required"
    }
    if (status === "OPTIONAL") {
      return "🔵 Optional"
    }
    return ""
  }

  return (
    <>
      {getGroups().map((group) => {
        const questionsDataInGroup = getDataByGroup(group);
        return (
          <>
            <StackCard
              marginTop={1}
              sx={{
                marginTop: "16px",
                borderLeft: "5px solid rgb(102, 187, 106)",
                borderBottom: "1px solid rgb(102, 187, 106)",
              }}
            >
              <BaasText
                mb={1}
                mt={3}
                variant="h6"
                sx={{
                  color: theme.palette.grey[600],
                }}
              >
                {(group && group.toUpperCase()) || "Select a category"}
              </BaasText>
              <BaasText mb={2} color="text.secondary">
                {questionsDataInGroup.length}{" "}
                {questionsDataInGroup.length > 1 ? "tests" : "test"}{" "}
                {group && "in this category"}
              </BaasText>
            </StackCard>
            {questionsDataInGroup.length > 0
              ? questionsDataInGroup.map((item) => {
                return (
                  <StackCard
                    sx={{
                      marginTop: "16px",
                      background: theme.palette.grey[100],
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <BaasText
                        variant="h6"
                        mb={1}
                        display="flex"
                        alignItems="center"
                        sx={{
                          color: theme.palette.grey[600],
                        }}
                      >
                        <b
                          style={{
                            marginRight: "4px",
                          }}
                        >
                          {item.question?.order}
                          {" - "}
                        </b>
                        {item.question?.name}{" "}
                        {item.answer?.value && (
                          <SuccessIcon style={{ marginLeft: "8px" }} />
                        )}
                      </BaasText>
                      <Box display="flex" flexDirection="column">
                        <BaasText
                          style={{
                            fontSize: "18px",
                            paddingBottom: "6px",
                          }}
                          color="text.secondary"
                        >
                          {getQuestionStatus(item.status)}
                        </BaasText>
                      </Box>
                    </Box>
                    <BaasText variant="body2" mb={1} mt={1}>
                      {item.question?.description}
                    </BaasText>
                    {item.question.docLink && (
                      <BaasText mb={1} color="primary">
                        <a
                          style={{ color: "#0096FF" }}
                          href={item.question.docLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Go to doc
                        </a>
                      </BaasText>
                    )}
                    {Boolean(item.answer?.value) && (
                      <BaasText mb={1} color="text.secondary">
                        Answer date: {item.answer.createTimestamp || "N/A"}
                      </BaasText>
                    )}
                    <AnswerInput
                      answerKey={item.question?.key}
                      answer={item.answer}
                      question={item.question}
                      earliest={item.question?.splunkConfig?.earliest}
                      questionId={item.question?.questionId}
                      loading={loading}
                      onAnswer={onAnswer}
                      submitingAnswer={submitingAnswer}
                    />
                  </StackCard>
                );
              })
              : "No data available"}
          </>
        );
      })}
    </>
  );
};

export default BaasTesterQuestionsList;
