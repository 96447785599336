import { useLocation } from "react-router-dom/cjs/react-router-dom";
import EnrolledAccountsDetails from "./EnrolledAccountsDetails";
import EnrolledAccountsList from "./EnrolledAccountsList";
import { EnrolledAccountsProvider } from "./contexts/EnrolledAccountsContext";
import { useMemo } from "react";

export default function EnrolledAccounts() {
  const location = useLocation();

  const isListPage = useMemo(() => {
    return !location.search;
  }, [location]);

  return (
    <EnrolledAccountsProvider>
      {isListPage && <EnrolledAccountsList />}
      {!isListPage && <EnrolledAccountsDetails />}
    </EnrolledAccountsProvider>
  );
}
