import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import {
  formatCurrency,
  formatDateTime,
  formatStatusColor,
  formatStatusName,
} from "../utils/format";

const PaymentCardStyle = styled.div`
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 4px 0 rgba(50, 50, 50, 0.2);
  }
`;

export default function PaymentCard({ paymentInfo }) {
  const [details, setDetails] = useState({});
  const history = useHistory();
  const theme = useTheme();

  const enrollmentDateTime = useMemo(() => {
    return formatDateTime(details.dateTime);
  }, [details]);

  const statusColor = useMemo(() => {
    return formatStatusColor(details.status);
  }, [details]);

  const statusName = useMemo(() => {
    return formatStatusName(details.status);
  }, [details]);

  const navigateToPage = () => {
    if (details.pactualId) {
      history.push(
        `/openFinance/payment-history?pactualId=${details.pactualId}`
      );
    }
  };

  useEffect(() => {
    const consent = paymentInfo?.consent;
    const payment = paymentInfo?.payment;
    setDetails({
      pactualId: payment?.pactualId,
      status: payment?.body?.data?.status,
      dateTime:
        payment?.body?.data?.statusUpdateDateTime ||
        payment?.body?.data?.creationDateTime,
      creditorName: consent?.body?.data?.creditor?.name,
      amount: formatCurrency(payment?.body?.data?.payment?.amount),
    });
  }, [paymentInfo]);

  return (
    <PaymentCardStyle onClick={navigateToPage}>
      <StackCard>
        <Box display="flex" justifyContent="space-between">
          <BaasText>TRANSFER SENT</BaasText>
          <BaasText>{enrollmentDateTime}</BaasText>
        </Box>
        <BaasText color={statusColor}>{statusName}</BaasText>
        <Box display="flex" justifyContent="space-between">
          <BaasText variant="h6" sx={{ color: theme.palette.grey[600] }}>
            {details.creditorName || "N/A"}
          </BaasText>
          <BaasText variant="h6" sx={{ color: theme.palette.grey[600] }}>
            {details.amount || "N/A"}
          </BaasText>
        </Box>
      </StackCard>
    </PaymentCardStyle>
  );
}
