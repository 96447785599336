import guidGenerator from "services/guidGenerator";

export const getNavigatorInfo = (accountTenure) => {

  return {
    isRootDevice: false,
    deviceId: localStorage.getItem("clientRequestIdJsr") || guidGenerator(),
    screenBrightness: 110,
    elapsedTimeSinceBoot: window.performance.now(),
    screenDimensions: {
      height: window.screen.height,
      width: window.screen.width,
    },
    osVersion: "11",
    userTimeZoneOffset: "-03:00",
    language: "pt",
    accountTenure: accountTenure,
    isCallInProgress: false,
    isDevModeEnabled: false,
    isMockGPS: false,
    isEmulated: false,
    isMonkeyRunner: false,
    isCharging: false,
    isUsbConnected: false,
    isRootedDevice: false,
    //
    // latitudeLongitude: getLocation(),
    // isCallingProgress: undefined,
    // isDevModeEnabled: undefined,
    // isEmulated: navigator.isEmulated,
    // isMockGPS: navigator.geolocation.isMocked,
    // isMonkeyRunner: navigator.monkeyRunner?.isRunning,
    // isCharging: navigator.battery?.isCharging,
    // antennaInformation: navigator.connection,
    // //TODO: Implement this information
    // integrity: {
    //   description: "string",
    //   appRecognitionVerdict:
    //     navigator.credentials?.appRecognitionVerdict || "string",
    //   deviceRecognitionVerdict:
    //     navigator.credentials?.deviceRecongnitionVerdict || "string",
    // },
  };
};

// function getLocation() {
//   let lat = 0;
//   let long = 0;
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(async function (position) {
//       lat = await position.coords.latitude;
//       long = await position.coords.longitude;
//     });
//   }
//   return { latitude: lat, longitude: long, type: "FINE" };
// }

function getDeviceIsConnected() {
  let isConnected = false;

  navigator.usb.getDevices(async function (devices) {
    isConnected = (await devices.length) > 0;
  });
  return isConnected;
}

function getTimezoneOffsetHours() {
  const date = new Date();

  const minutes = date.getTimezoneOffset();

  const hours = Math.abs(Math.floor(minutes / 60));
  const restMinutes = Math.abs(minutes % 60);

  const sinal = minutes < 10 ? "-" : "+";

  const formatedHours = hours < 10 ? "0" + hours : hours;
  const formatedMinutes = hours < 10 ? "0" + restMinutes : restMinutes;

  return sinal + formatedHours + ":" + formatedMinutes;
}
