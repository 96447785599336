import InfoIcon from "@mui/icons-material/Info";
import BaasPagination from "components/molecules/BaasPagination/BaasPagination";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import ModalJsonInfo from "components/organism/ModalJsonInfo/ModalJsonInfo";
import moment from "moment";
import React, { useState } from "react";
import { currencyFormatter } from "helpers/currencyFormatter";
import { pixSearchWhitelist } from "./config/pixWhitelist";

const DashboardSearchList = ({
  data,
  loading,
  fetch,
  limit,
  queryVariables,
  displayPagination,
  operationType,
}) => {
  const [page, setPage] = useState(0);

  const paginationData = {
    totalFound: data?.totalFound,
    totalReturned: data?.totalReturned,
    startingFrom: data?.startingFrom,
  };

  const [dataModalInfo, setDataModalInfo] = useState({
    state: false,
    data: {},
  });

  const conditionalAction = (item) => {
    let conditions = [
      {
        onClick: () =>
          setDataModalInfo({
            state: true,
            data: item,
          }),
        icon: InfoIcon,
        tooltip: "More info",
      },
    ];
    return conditions;
  };

  const onSelectPage = (page) => {
    setPage(page);
    fetch({
      ...queryVariables,
      page,
      limit,
    });
  };

  const getValueAmount = (value) => {
    if (value?.original) {
      return Number(value?.original).toFixed(2);
    }

    if (value) {
      return Number(value).toFixed(2);
    }
  };

  const filteredData = operationType === "pix" ? (data?.items || []).filter(item => {
    return pixSearchWhitelist.includes(item.entity)
  }) : data?.items || [];

  return (
    <>
      <DinamicTable
        data={filteredData}
        loading={loading}
        defaultSort={{
          key: "createTimestamp",
          orientation: "desc",
        }}
        getExtraActions={(data) => conditionalAction(data)}
        tableSchema={[
          {
            header: "Pactual Id",
            key: "pactualId",
            tooltip: true,
            sort: true,
            style: {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "noWrap",
            },
          },
          {
            header: "Create Date",
            key: "createTimestamp",
            formatValue: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
            sort: true,
          },
          {
            header: "Status",
            key: "status",
            sort: true,
          },
          {
            header: "Entity",
            key: "entity",
            sort: true,
          },
          {
            header: "Amount",
            key: "body.amount",
            sort: true,
            formatValue: (value) =>
              value
                ? `R$ ${operationType === "pix"
                  ? getValueAmount(value)
                  : currencyFormatter(value / 100)
                }`
                : "N/A",
          },
        ]}
      />
      <ModalJsonInfo modal={dataModalInfo} setModal={setDataModalInfo} />
      {displayPagination && data?.items?.length > 0 && (
        <BaasPagination
          page={page}
          resultsCount={paginationData.totalFound}
          onSelectPage={onSelectPage}
          rowsPerPage={limit}
        />
      )}
    </>
  );
};

export default DashboardSearchList;
