import { baasApiCall } from "services/apiCall";

export const mutationSimulateCashIn = (payload) => {
    return baasApiCall(
        "POST",
        `/tr/transfer/internal/simulate-cash-in`,
        payload
    )
        .then((data) => data.json())
        .catch((error) => error.json());
};
