import CheckIcon from "@mui/icons-material/Check";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import { useState } from "react";
import { toast } from "react-toastify";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import { useInterval } from "helpers/useInterval";
import { useEffect } from "react";
import { currencyFormatter } from "helpers/currencyFormatter";
import { queryPoolingPayment } from "api/jsr/jsr-api";
import { Box, Button } from "@mui/material";

export const PoolingPanel = ({ formValues, setFormValues, handleRestartFlow }) => {
    const [delay, setDelay] = useState(1500);
    const theme = useTheme();
    const [state, setState] = useState({
        urlPactuaId: null,
        urlStatus: null,
        trStatus: null,
        trData: null,
        poolingCount: 0,
        error: false,
        useBaas: JSON.parse(sessionStorage.getItem("useBaas")),
    });

    const updateTr = async () => {
        const params = {
            pactualId: formValues.enrolledAccount.pactualId,
            consentPactualId: formValues.consentPactualId
        };
        const trData = await queryPoolingPayment(params);
        if (trData.status < 300 && state.poolingCount < 30) {
            setState({
                ...state,
                trData: trData.body[0],
                trStatus: trData.body[0]?.status,
                poolingCount: state.poolingCount + 1,
            });
            if (
                trData.body[0]?.status === "SETTLEMENT_COMPLETED" ||
                trData.body[0]?.status === "CONSENT_REJECTED"
            )
                setDelay(null);
        } else {
            setDelay(null);
            setState({
                ...state,
                error: true,
            });
            if ("errors" in trData) {
                trData.errors.map((error) => {
                    return toast.error(error.message);
                });
            }
        }
    };

    useInterval(updateTr, delay);

    useEffect(() => {
        toastControl(state.trStatus);
    }, [state.trStatus]);

    const toastControl = (status) => {
        if (status) {
            if (status === "SETTLEMENT_COMPLETED") {
                return toast.success(`Transaction status update: ${status}`);
            } else if (status === "REJECTED" || status === "CONSENT_REJECTED") {
                return toast.error(`Transaction status update: ${status}`);
            } else {
                return toast.info(`Transaction status update: ${status}`);
            }
        }
    };

    return (
        <>
            <BaasRow item spacing={1}>
                <BaasText variant="h6">Transaction Status</BaasText>
            </BaasRow>
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                <div style={{ textAlign: "center" }}>
                    {state.error !== true && state.trData?.status === "SETTLEMENT_COMPLETED" ? (
                        <>
                            <CheckIcon color="success" fontSize="large" />
                            <BaasText textAlign="center" variant="h5" color="success.main">
                                Request successful!
                            </BaasText>
                        </>
                    ) : null}
                    {state.trData?.status !== "SETTLEMENT_COMPLETED" &&
                        state.trData?.status !== "REJECTED" &&
                        state.trData?.status !== "CONSENT_REJECTED" &&
                        !state.error ? (
                        <>
                            <CircularProgress color="inherit" />
                            <BaasText textAlign="center" variant="h6">
                                {state.trData
                                    ? state.trData.status
                                    : "Loading transaction status..."}
                            </BaasText>
                        </>
                    ) : null}
                    {state.error ||
                        state.trData?.status === "REJECTED" ||
                        state.trData?.status === "CONSENT_REJECTED" ? (
                        <>
                            <DoDisturbIcon sx={{ color: "#D22D4B" }} fontSize="large" />
                            <BaasText textAlign="center" variant="h5" color="error.main">
                                {state.trData?.status === "REJECTED" ||
                                    state.trData?.status === "CONSENT_REJECTED"
                                    ? "Transaction rejected..."
                                    : "Failed to load transaction status..."}
                            </BaasText>
                            <BaasGrid item xs={12}>
                                <Box mb={2} mt={3} display="flex" justifyContent="space-between">
                                    <Button
                                        style={{ minWidth: "150px" }}
                                        color="primary"
                                        variant="outlined"
                                        onClick={handleRestartFlow}

                                    >
                                        Restart Flow
                                    </Button>

                                </Box>
                            </BaasGrid>
                        </>
                    ) : null}
                </div>
            </BaasGrid>
            {state.trData ? (
                <>
                    <BaasRow item spacing={1}>
                        <BaasText variant="h6">Transaction Information</BaasText>
                    </BaasRow>
                    <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                        <BaasRow container spacing={1}>
                            <BaasGrid item xs={12}>
                                <BaasInfoDisplay
                                    label={"Amount:"}
                                    value={`R$ ${currencyFormatter(
                                        state.trData?.body?.data?.payment.amount / 100
                                    )}`}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"EndToEnd Id:"}
                                    value={state.trData?.body?.data?.endToEndId}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Pactual Id:"}
                                    value={state.trData?.pactualId}
                                />
                            </BaasGrid>
                        </BaasRow>
                    </BaasGrid>
                    <BaasRow item spacing={1}>
                        <BaasText variant="h6">Creditor Account Information</BaasText>
                    </BaasRow>
                    <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                        <BaasRow container spacing={1}>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Account Number:"}
                                    value={state.trData?.body?.data?.creditorAccount?.number}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Account Type:"}
                                    value={state.trData?.body?.data?.creditorAccount?.accountType}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"ISPB:"}
                                    value={state.trData?.body?.data?.creditorAccount?.ispb}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Issuer:"}
                                    value={state.trData?.body?.data?.creditorAccount?.issuer}
                                />
                            </BaasGrid>
                        </BaasRow>

                    </BaasGrid>
                    <BaasRow item spacing={1}>
                        <BaasText variant="h6">Debtor Account Information</BaasText>
                    </BaasRow>
                    <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                        <BaasRow container spacing={1}>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Account Number:"}
                                    value={state.trData?.body?.data?.debtorAccount?.number}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Account Type:"}
                                    value={state.trData?.body?.data?.debtorAccount?.accountType}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"ISPB:"}
                                    value={state.trData?.body?.data?.debtorAccount?.ispb}
                                />
                            </BaasGrid>
                            <BaasGrid item xs={12} md={6}>
                                <BaasInfoDisplay
                                    label={"Issuer:"}
                                    value={state.trData?.body?.data?.debtorAccount?.issuer}
                                />
                            </BaasGrid>
                        </BaasRow>
                    </BaasGrid>
                    <BaasGrid item xs={12}>
                        <Box mb={2} mt={3} display="flex" justifyContent="space-between">
                            <Button
                                style={{ minWidth: "150px" }}
                                color="primary"
                                variant="outlined"
                                onClick={handleRestartFlow}

                            >
                                Restart Flow
                            </Button>

                        </Box>
                    </BaasGrid>
                </>
            ) : null}
        </>
    );
};
