import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { queryGetEnrollmentsByCpf } from "api/jsr/jsr-api";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import { useAccount } from "providers/account";
import { useEffect, useState } from "react";
import { NewEnrollmentPanel } from "./NewEnrollmentPanel";
import { FidoBiometricsPanel } from "./FidoBiometricsPanel";
import AutocompleteDefault from "components/atoms/Autocomplete/AutocompleteDefault";

export const EnrollmentsPanel = ({
    formValues,
    setFormValues,
    handleNext,
    handleRestartFlow
}) => {
    const [isEnrollingAccount, setIsEnrollingAccount] = useState(false);
    const {
        fetch: loadEnrollments,
        loading: loadingEnrollments,
        data: enrollmentsData,
    } = useCustomQuery(queryGetEnrollmentsByCpf);

    useEffect(() => {
        if (formValues.isFidoBiometrics === false) {
            loadEnrollments({
                values: {
                    taxId: formValues.taxId,
                },
            });
        }
    }, [loadEnrollments, formValues.isFidoBiometrics]);

    const handleSelectEnrolledAccount = (enrolledAccount) => {
        setFormValues({
            ...formValues,
            enrolledAccount: enrolledAccount
        })
    }

    if (formValues.isFidoBiometrics) {
        return (<FidoBiometricsPanel formValues={formValues} setFormValues={setFormValues} />)
    }

    if (isEnrollingAccount) {
        return (<NewEnrollmentPanel formValues={formValues} setFormValues={setFormValues} setIsEnrollingAccount={setIsEnrollingAccount} />)
    }

    return (
        <>

            <>
                <BaasGrid item xs={12}>
                    <Box display="flex" alignItems={"center"} mt="16px">
                        <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
                            Participant Information
                        </BaasText>
                    </Box>
                    <BaasGrid item xs={12}>
                        <Box
                            mb={2}
                            mt={2}
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setIsEnrollingAccount(true)}
                                style={{ marginTop: "16px", width: "180px" }}
                            >
                                New account enrollment
                            </Button>
                        </Box>
                    </BaasGrid>
                    <Box display="flex" alignItems={"center"} mt="16px">
                        <AutocompleteDefault
                            name="enrolled Account"
                            label={
                                loadingEnrollments ? "Loading" : "Select enrolled account"
                            }
                            isLoading={loadingEnrollments}
                            style={{ width: "100%" }}
                            fullwidth
                            onClear={() => handleSelectEnrolledAccount(null)}
                            onSelect={(value) => {
                                handleSelectEnrolledAccount(value)
                            }
                            }
                            options={(enrollmentsData || []).filter((enrollment) => {
                                return enrollment.status === "AUTHORISED";
                            })}
                            getOptionLabel={(option) => `${option.body.data.enrollmentName} | Acc:  ${option.body?.data?.debtorAccount?.number} | ISPB: ${option.body?.data?.debtorAccount?.ispb}`}
                        />
                    </Box>
                </BaasGrid>
                <BaasGrid item xs={12}>
                    <Box mb={2} mt={3} display="flex" justifyContent="space-between">
                        <Button
                            style={{ minWidth: "150px" }}
                            color="primary"
                            variant="outlined"
                            onClick={handleRestartFlow}

                        >
                            Restart Flow
                        </Button>
                        <Button
                            style={{ minWidth: "150px" }}
                            color="primary"
                            variant="contained"
                            onClick={handleNext}
                            disabled={!formValues?.enrolledAccount}
                        >
                            Next
                        </Button>
                    </Box>
                </BaasGrid>
            </>
        </>
    );
};
