import { useLocation } from "react-router-dom/cjs/react-router-dom";
import PaymentHistoryDetails from "./PaymentHistoryDetails";
import PaymentHistoryList from "./PaymentHistoryList";
import { PaymentHistoryProvider } from "./contexts/PaymentHistoryContext";
import { useMemo } from "react";

export default function PaymentHistory() {
  const location = useLocation();

  const isListPage = useMemo(() => {
    return !location.search;
  }, [location]);

  return (
    <PaymentHistoryProvider>
      {isListPage && <PaymentHistoryList />}
      {!isListPage && <PaymentHistoryDetails />}
    </PaymentHistoryProvider>
  );
}
