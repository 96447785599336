import { Box } from "@mui/material";
import BaasText from "components/atoms/Text/BaasText";
import { labelFormatter } from "helpers/labelFormatter";
import React from "react";

const BaasRowDisplay = ({ value, label, key, ...props }) => {

    return (
        <Box display="flex" width="100%">
            <BaasText variant="subtitle2">
                {label ? label : labelFormatter(key)}:
            </BaasText>
            <BaasText
                variant="body1"
                style={{ wordBreak: "break-all", opacity: "0.8", marginLeft: "4px" }}
                color="grey.600"
            >
                {value}
            </BaasText>
        </Box>

    );
};

export default BaasRowDisplay;
