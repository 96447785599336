import { useTheme } from "@mui/system";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/HeaderLogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import BaasNavBar from "./NavBar";
import { SwipeableDrawer } from "@mui/material";
import './NavBarIos.css';


const NavBarBackground = styled.div`
  width: 100%;
  height: 75px;
  position: fixed;
  background-color: ${(props) => props.theme.palette.grey[700]};
  color: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  justify-content: space-between;
  align-items: center;
    z-index: 100;
`;


const BaasNavBarMobile = () => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const iOS =
    typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <NavBarBackground theme={theme}>
      <BaasIconButton size="large" onClick={() => setOpenDrawer(true)}>
        <MenuIcon color="grey.100" />
      </BaasIconButton>
      <Logo style={{ margin: "20px" }} />
      <BaasIconButton size="large" disabled>
        <NotificationsNoneOutlinedIcon />
      </BaasIconButton>
      {iOS ? (<> <div className={`drawer ${openDrawer ? 'open' : ''}`}>
        <ul className="drawer-menu">
          <BaasNavBar onClose={() => setOpenDrawer(false)} />
        </ul>
      </div>
        <div className={`overlay ${openDrawer ? 'open' : ''}`} onClick={() => setOpenDrawer(!openDrawer)}></div></>) : (
        <>
          <SwipeableDrawer
            anchor="left"
            sx={{ zIndex: 100 }}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
          >
            <BaasNavBar onClose={() => setOpenDrawer(false)} />
          </SwipeableDrawer >
        </>
      )}
    </NavBarBackground>
  );
};

export default BaasNavBarMobile;
