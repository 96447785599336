import { Box, useMediaQuery, useTheme, Skeleton } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasStep from "components/atoms/Step/Step";
import BaasStepper from "components/atoms/Stepper/Stepper";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React, { useEffect, useState } from "react";
import guidGenerator from "services/guidGenerator";
import { ConfirmationPanel } from "./components/ConfirmationPanel";
import { EnrollmentsPanel } from "./components/EnrollmentsPanel";
import { PoolingPanel } from "./components/PoolingPanel";
import { TransferDataPanel } from "./components/TransferDataPanel";
import { openFinanceSteps } from "./stepsConfig";
import { useCustomQuery } from "helpers/useCustomQuery";
import { queryGetLoggedUserCpf } from "api/jsr/jsr-api";
import Endpoints from "constants/endpoints";

const steps = [
  TransferDataPanel,
  EnrollmentsPanel,
  ConfirmationPanel,
  PoolingPanel,
];

export default function OpenFinance() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = React.useState(0);
  const url = new URL(window.location.href);
  const enrollmentPactualId = url.searchParams.get("id");
  const enrollmentStatus = url.searchParams.get("status");
  const isProduction = Endpoints.Environment === "Production";

  const [formValues, setFormValues] = useState({
    amount: "",
    taxId: "",
    pixKey: "",
    enrolledAccount: "",
    enrollmentName: "",
    clientRequestId: guidGenerator(),
    isFidoBiometrics: Boolean(enrollmentPactualId),
    biometricsPactualId: enrollmentPactualId,
    consentPactualId: "",
  });
  const [authPoolingPactualid, setAuthPoolingPactualId] = useState();
  const [values, setValues] = useState();

  const {
    fetch: loadLoggedUserInfo,
    loading: loadingLoggedUserInfoInfo,
    data: loggedUserInfo,
  } = useCustomQuery(queryGetLoggedUserCpf);

  useEffect(() => {
    if (enrollmentPactualId) {
      setActiveStep(1);
    }
  }, []);

  useEffect(() => {
    if (isProduction) {
      loadLoggedUserInfo({
        onCompleted: (data) => setFormValues({ ...formValues, taxId: data.cpf }),
      });
    }
  }, []);


  const handleRestartFlow = () => {
    setFormValues({
      amount: "",
      pixKey: "",
      taxId: isProduction ? formValues.taxId : "",
      enrolledAccount: "",
      enrollmentName: "",
      clientRequestId: guidGenerator(),
      isFidoBiometrics: false,
      biometricsPactualId: "",
      consentPactualId: "",
    })
    localStorage.removeItem("clientRequestIdJsr");
    localStorage.removeItem("jsrValues");
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const StepComponent = steps[activeStep];

  const componentProps = {
    formValues,
    handleRestartFlow,
    setFormValues,
    enrollmentPactualId,
    enrollmentStatus,
    handleNext,
    handleBack,
    authPoolingPactualid,
    setAuthPoolingPactualId,
    setValues,
    values,
    isProduction,
  };

  return (
    <BaasFlex width="100%" flexDirection="column">
      <BaasPageHeader>Open Finance</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid p="16px 0px" xs={12}>
          <Box display="flex" alignItems="center" mb={2}>
            <BaasText variant="h6">Open Finance Flow</BaasText>
          </Box>
        </BaasGrid>
        <StackCard>
          <BaasGrid item sm={6}>
            <Box mb={4}>
              <BaasStepper activeStep={activeStep} orientation={isMobile ? "vertical" : "horizontal"}>
                {openFinanceSteps.map((step, index) => {
                  const stepProps = {};
                  return (
                    <BaasStep key={step.title} {...stepProps}>
                      <StepLabel>{step.title}</StepLabel>
                    </BaasStep>
                  );
                })}
              </BaasStepper>
            </Box>
          </BaasGrid>
          {loadingLoggedUserInfoInfo ? (
            <Skeleton height="180px" />
          ) : (
            <StepComponent {...componentProps} />)}
        </StackCard>
      </BaasContentGrid>
    </BaasFlex>
  );
}
