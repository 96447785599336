import { Box, Button, TextField, useTheme, useMediaQuery, } from "@mui/material";
import MoneyInput from "@rschpdr/react-money-input";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";

export const TransferDataPanel = ({ handleNext, formValues, setFormValues, isProduction }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const updateField = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <BaasGrid item xs={12}>
                <Box display="flex" mb={2} mt={2}>
                    <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
                        New transfer without redirect.
                    </BaasText>
                </Box>
            </BaasGrid>
            {isMobile ? (
                <BaasGrid item xs={12}>
                    <TextField
                        fullWidth
                        onChange={(e) => updateField(e)}
                        required
                        value={formValues?.amount}
                        inputProps={{
                            step: 0.01,
                        }}
                        name="amount"
                        label="Transfer Amount"
                        type="number"
                        style={{ margin: "8px 0px", width: "100%" }}

                    />
                </BaasGrid>) : (
                <BaasGrid item xs={12}>
                    <MoneyInput
                        name="amount"
                        customInput={TextField}
                        onChange={(e) => updateField(e)}
                        label="Transfer amount"
                        value={formValues?.amount}
                        currencyConfig={{
                            locale: "pt-BR",
                            currencyCode: "BRL",
                            currencyDisplay: "symbol",
                        }}
                        style={{ margin: "8px 0px", width: "100%" }}
                    />
                </BaasGrid>)
            }
            <BaasGrid item xs={12}>
                <TextField
                    fullWidth
                    required
                    value={formValues?.pixKey}
                    name="pixKey"
                    onChange={(e) => updateField(e)}
                    label="PIX Key"
                    style={{ margin: "8px 0px", width: "100%" }}
                />
            </BaasGrid>
            <BaasGrid item xs={12}>
                <TextField
                    fullWidth
                    onChange={(e) => updateField(e)}
                    required
                    value={formValues?.taxId}
                    name="taxId"
                    label="CPF"
                    disabled={isProduction}
                    style={{ margin: "8px 0px", width: "100%" }}
                />
            </BaasGrid>
            <BaasGrid item xs={12}>
                <Box mb={2} mt={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleNext}
                        disabled={!formValues?.taxId || !formValues?.pixKey || !formValues?.amount}
                    >
                        Next
                    </Button>
                </Box>
            </BaasGrid>
        </>
    );
};
