import { Box, Button, TextField } from "@mui/material";
import { mutationFidoConsentPixKey } from "api/jsr/jsr-api";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasRowDisplay from "components/molecules/RowDisplay/RowDisplay";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useCallback, useState } from "react";
import { useCustomMutation } from "helpers/useCustomMutation";
import { mutationFidoConsentAuthorize } from "api/jsr/jsr-api";
import { getNavigatorInfo } from "helpers/getNavigatorInfo";
import moment from "moment";
import { toast } from "react-toastify";
import { mutationSendLog } from "api/jsr/jsr-api";
import { useMutation } from "react-query";

export const ConfirmationPanel = ({ handleNext, formValues, setFormValues, handleRestartFlow }) => {
    const [pixKeyConsentData, setPixKeyConsentData] = useState(null);

    const {
        mutate: mutateConsentPixKey,
        isSubmitting: isSubmitingConsentPixKey,
    } = useCustomMutation(mutationFidoConsentPixKey);

    const { mutate: mutateSendLog } = useMutation((e) => mutationSendLog(e));

    const {
        mutate: mutateFidoAuthorize,
        isSubmitting: isSubmitingAuthorize,
    } = useCustomMutation(mutationFidoConsentAuthorize);

    const handleSubmitConsentPixKey = useCallback(() => {
        mutateConsentPixKey(
            {
                pactualId: formValues.enrolledAccount.pactualId,
                pixKey: formValues.pixKey,
                amount: formValues.amount * 100,
                clientRequestId: formValues.clientRequestId,
                authorizationServerId: formValues.enrolledAccount?.body?.authorizationServerId,
                loggedUser: {
                    identification: formValues.taxId,
                    type: "CPF"
                },
                metadata: {
                    platform: "BROWSER",
                    os: "WINDOWS",
                    osVersion: "10"
                }
            },
            (response) => {
                localStorage.setItem("clientRequestIdJsr", formValues.clientRequestId);
                setPixKeyConsentData(response?.body);
                setTimeout(() => {
                    handleSubmitAuthorize(response?.body)
                }, 300);
            }
        );
    }, []);

    function arrayBufferToBase64URL(buffer) {
        const uint8Array = new Uint8Array(buffer);
        const binaryString = [...uint8Array.values()]
            .map((byte) => String.fromCodePoint(byte))
            .join("");
        const base64EncodedString = btoa(binaryString);
        const base64URLEncodedString = base64EncodedString
            .replaceAll("+", "-")
            .replaceAll("/", "_")
            .replaceAll("=", "");
        return base64URLEncodedString;
    }

    function base64URLDecode(value) {
        const base64URLDecodedString = value
            .replaceAll("-", "+")
            .replaceAll("_", "/");
        const padding = base64URLDecodedString.length % 4 === 0 ? '' : '='.repeat(4 - (base64URLDecodedString.length % 4));
        const base64WithPadding = base64URLDecodedString + padding;
        return base64WithPadding;
    }

    const handleSubmitAuthorize = useCallback(async (pixKeyConsentData) => {
        if (pixKeyConsentData?.body.integrationErrorDetail) {
            return toast.error(pixKeyConsentData?.body.integrationErrorDetail?.detail)
        }
        const challenge = Uint8Array.from(atob(base64URLDecode(pixKeyConsentData?.body?.enrollmentAuthorization?.fidoSignOptions?.data.challenge)), c => c.charCodeAt(0));
        const allowCredentials = pixKeyConsentData?.body.enrollmentAuthorization.fidoSignOptions.data.allowCredentials.map(cred => ({
            type: cred.type,
            id: Uint8Array.from(atob(base64URLDecode(cred.id)), c => c.charCodeAt(0))
        }));
        try {
            const assertion = await navigator.credentials.get({
                publicKey: {
                    challenge: challenge,
                    rpId: window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "localhost" : pixKeyConsentData?.body?.enrollmentAuthorization?.fidoSignOptions?.data.rpId,
                    allowCredentials: allowCredentials,
                    timeout: pixKeyConsentData?.body?.enrollmentAuthorization?.fidoSignOptions?.data.timeout || undefined,
                    userVerification: pixKeyConsentData?.body?.enrollmentAuthorization?.fidoSignOptions?.data?.userVerification || undefined,
                    extensions: pixKeyConsentData?.body?.enrollmentAuthorization?.fidoSignOptions?.data?.extensions || undefined,
                }
            });
            mutateFidoAuthorize(
                {
                    consentPactualId: pixKeyConsentData?.pactualId,
                    pactualId: formValues.enrolledAccount?.pactualId,
                    fidoAssertion: {
                        id: assertion.id,
                        rawId: arrayBufferToBase64URL(assertion.rawId),
                        type: assertion.type,
                        response: {
                            clientDataJSON: arrayBufferToBase64URL(assertion.response.clientDataJSON),
                            authenticatorData: arrayBufferToBase64URL(assertion.response.authenticatorData),
                            signature: arrayBufferToBase64URL(assertion.response.signature),
                            userHandle: assertion.response.userHandle ? arrayBufferToBase64URL(assertion.response.userHandle) : null
                        },
                        clientExtensionResults: assertion.getClientExtensionResults()
                    },
                    riskSignals: getNavigatorInfo(
                        moment("2024-05-23").format("YYYY-MM-DD")
                    )
                },
                (response) => {
                    setFormValues({
                        ...formValues,
                        consentPactualId: response.body?.pactualId
                    })
                    handleNext();
                },
            );
        } catch (e) {
            if ("errors" in e) {
                e.errors.map((error) => {
                    return toast.error(error.message);
                });
            }
            mutateSendLog(
                {
                    error: `${e.name} + " - " + ${e.message} + " - " + ${e.stack}`,
                    pactualId: formValues?.biometricsPactualId
                },
                {
                    onSuccess: () => { },
                    onError: () => { }
                }
            );
        }
    }, [formValues]);

    const handleSubmit = () => {
        if (!pixKeyConsentData) {
            handleSubmitConsentPixKey();
        }
        else {
            handleSubmitAuthorize(pixKeyConsentData)
        }
    }

    const getButtonLabel = () => {
        if (!pixKeyConsentData) {
            return "Consent"
        } else {
            return "FIDO Authorize"
        }
    }

    return (
        <>
            <BaasGrid item xs={12}>
                <Box display="flex" mb={2} mt={2}>
                    <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
                        Plase, confirm the tansfer information before proceed.
                    </BaasText>
                </Box>
            </BaasGrid>
            <BaasGrid item xs={12}>
                <BaasRowDisplay label="Amount" value={formValues.amount ? `R$${currencyFormatter(Number(formValues.amount))}` : 0} />
            </BaasGrid>
            <BaasGrid item xs={12}>
                <BaasRowDisplay label="Tax Id" value={formValues.taxId} />
            </BaasGrid>
            <BaasGrid item xs={12}>
                <BaasRowDisplay label="Pix Key" value={formValues.pixKey} />
            </BaasGrid>
            <BaasGrid item xs={12}>
                <BaasRowDisplay label="Date" value={moment().format("YYYY/MM/DD HH:mm:ss")} />
            </BaasGrid>
            <BaasGrid item xs={12}>
                <Box mb={2} mt={3} display="flex" justifyContent="space-between">
                    <Button
                        style={{ minWidth: "150px" }}
                        color="primary"
                        variant="outlined"
                        onClick={handleRestartFlow}

                    >
                        Restart Flow
                    </Button>
                    <Button
                        style={{ minWidth: "150px" }}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSubmitingConsentPixKey || isSubmitingAuthorize}
                    >
                        {isSubmitingConsentPixKey || isSubmitingAuthorize ? "Loading" : getButtonLabel()}
                    </Button>
                </Box>
            </BaasGrid>
        </>
    );
};
