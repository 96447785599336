import { baasApiCall } from "services/apiCall";

export const consentValidateAuthorize = (params) => {
    return baasApiCall("POST", `/bk/mock/tpp/recurring/consent/validate/authorize/${params.consentId}`)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const consentValidateReject = (params) => {
    return baasApiCall("POST", `/bk/mock/tpp/recurring/consent/validate/reject/${params.consentId}`)
        .then((data) => data.json())
        .catch((error) => error.json());
};