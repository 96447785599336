import { MenuItem, TextField, useTheme } from "@mui/material";
import { queryGetDashboardData } from "api/pixLimitValidator/pixLimitValidator";
import { useQueryGetUserInfo } from "api/security-api";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { currencyFormatter } from "helpers/currencyFormatter";
import getSafe from "helpers/getSafe";
import { useCustomQuery } from "helpers/useCustomQuery";
import { useInterval } from "helpers/useInterval";
import { useEffect, useState } from "react";
import MetricsPanel from "./components/MetricsPanel";
import MetricsPanelSkeleton from "./components/MetricsPanelSkeleton";

const DASH_INTERVAL_FETCH = 30000;

const PixLimitValidatorDashboard = (props) => {
  const userRoles = JSON.parse(sessionStorage.getItem("BAAS_USER_ROLES")) || [];
  const hasTransferPermission = userRoles.includes("pix-limit-valid-v2-w");
  const theme = useTheme();
  const { data: userInfo } = useQueryGetUserInfo();
  const [transferOpen, setTransferOpen] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [errorsCount, setErrorsCount] = useState(0);
  const [intervalValues, setIntervalValues] = useState({
    period: "60",
    interval: "1",
  });

  const {
    fetch: loadDashbordData,
    loading: loadingDashboard,
    data: dashboardData,
  } = useCustomQuery(queryGetDashboardData);

  useEffect(() => {
    if (userInfo?.clientId) {
      loadDashbordData({
        values: {
          clientId: userInfo.clientId,
          timeInterval: intervalValues.interval,
          periodMinutes: intervalValues.period,
        },
        emptyWarning: true,
        objectPath: "body",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.clientId, intervalValues]);

  useInterval(() => {
    if (!transferOpen) {
      setRefreshing(true);
      loadDashbordData({
        values: {
          clientId: userInfo.clientId,
          timeInterval: intervalValues.interval,
          periodMinutes: intervalValues.period,
        },
        emptyWarning: true,
        objectPath: "body",
        onCompleted: () => {
          setRefreshing(false);
        },
      });
    }
  }, DASH_INTERVAL_FETCH);

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
      <BaasContentGrid spacing={1}>
        <BaasRow container spacing={1}>
          <BaasText sx={{ paddingLeft: "8px" }} variant="h6">
            Hello, {getSafe(userInfo, "fullName")}
          </BaasText>
        </BaasRow>
        <StackCard display="flex" alignItems="center">
          <BaasGrid spacing={1}>
            <BaasGrid>
              <BaasText sx={{ marginBottom: "24px" }} color="textSecondary">
                Bellow you can find the dashboards for your client's Pix Limit
                Validator operation within BTG Pactual Banking as a Service. The
                data displayed is real-time based on the time frame selected.
              </BaasText>
            </BaasGrid>
            <BaasGrid>
              <TextField
                size="small"
                label="Period"
                sx={{ minWidth: "180px" }}
                variant="standard"
                select
                SelectProps={{
                  value: intervalValues.period,
                  onChange: (e) => {
                    setIntervalValues({
                      period: e.target.value,
                      interval: 1,
                    });
                  },
                }}
              >
                <MenuItem value="60">Last 60 minutes</MenuItem>
                <MenuItem value="30">Last 30 minutes</MenuItem>
                <MenuItem value="15">Last 15 minutes</MenuItem>
              </TextField>
            </BaasGrid>
          </BaasGrid>
        </StackCard>{" "}
        <BaasGrid p="16px 4px 0px 0px" item>
          <BaasGrid display="flex">
            <BaasText variant="h6">Cash In / Cash Out</BaasText>
          </BaasGrid>
        </BaasGrid>
        <BaasGrid p="0px 0px" mb={2} item>
          <BaasGrid display="flex">
            <BaasText variant="body1" color="textSecondary">This section provides a comprehensive view of your Cash In and Cash Out activities. Track the total number of transactions for both Cash In (funds added to your account) and Cash Out (funds withdrawn or transferred).</BaasText>
          </BaasGrid>
        </BaasGrid>
        {!dashboardData || (!refreshing && loadingDashboard) ? (
          <>
            <MetricsPanelSkeleton />
            <MetricsPanelSkeleton />
            <MetricsPanelSkeleton />
          </>
        ) : (
          <>
            <StackCard>
              <MetricsPanel
                pb={2}
                loading={loadingDashboard}
                chartData={{
                  title: "CashOut Transactions Consolidated",
                  items:
                    dashboardData?.cashOutAmountProcessed?.items.length > 0
                      ? dashboardData?.cashOutAmountProcessed?.items
                      : [],
                  color: theme.palette.primary.main,
                }}
                rightCard={{
                  title: "CashOut Amount",
                  subtitle: "Processed Total Value",
                  value:
                    `R$ ${currencyFormatter(
                      Number(
                        dashboardData?.cashOutAmountProcessed?.total ===
                          undefined
                          ? 0
                          : dashboardData?.cashOutAmountProcessed?.total
                      )
                    )} ` || "N/A",
                  instruction:
                    "Total amount of Pix CashOuts with confirmed status in the selected time-frame.",
                }}
                leftCard={{
                  title: "Active Clients",
                  value: dashboardData?.quantityActiveClients || "N/A",
                  instruction:
                    "Number of different TaxIds that made transactions in the selected time-frame.",
                }}
              />
              <MetricsPanel
                chartData={{
                  title: "CashIn Amount Processed",
                  items:
                    dashboardData?.cashInAmountProcessed?.items.length > 0
                      ? dashboardData?.cashInAmountProcessed?.items
                      : [],
                  color: theme.palette.primary.main,
                }}
                pb={1}
                rightCard={{
                  title: "CashIn Amount",
                  subtitle: "Processed Total Value",
                  value:
                    `R$ ${currencyFormatter(
                      Number(
                        dashboardData?.cashInAmountProcessed?.total ===
                          undefined
                          ? 0
                          : dashboardData?.cashInAmountProcessed?.total
                      )
                    )} ` || "N/A",
                  instruction:
                    "Total amount of Pix CashIns with confirmed status in the selected time-frame.",
                }}
              />
            </StackCard>
            <BaasGrid p="16px 4px 0px 0px" item>
              <BaasGrid display="flex">
                <BaasText variant="h6">QR Codes</BaasText>
              </BaasGrid>
            </BaasGrid>
            <BaasGrid p="0px 0px" mb={2} item>
              <BaasGrid display="flex">
                <BaasText variant="body1" color="textSecondary">This section provides an overview of your QR Code activities. You can track key metrics such as the number of QR Codes created, the number of QR Codes paid, and the conversion rate.</BaasText>
              </BaasGrid>
            </BaasGrid>
            <StackCard>
              <MetricsPanel
                pb={2}
                chartData={{
                  title: "QR Codes Created",
                  items:
                    dashboardData?.qrCode?.itemsCreated.length > 0
                      ? dashboardData?.qrCode?.itemsCreated
                      : [],
                  color: theme.palette.primary.main,
                }}
                rightCard={{
                  title: "Total QR Codes Created",
                  value: dashboardData?.qrCode?.totalCreated || "N/A",
                }}
                leftCard={{
                  title: "QR Code Conversion Rate",
                  value: dashboardData?.qrCode?.conversionRate
                    ? `${dashboardData?.qrCode?.conversionRate}%`
                    : "N/A",
                }}
              />
              <MetricsPanel
                pb={1}
                chartData={{
                  title: "QR Codes Paid",
                  items:
                    dashboardData?.qrCode?.itemsPaid.length > 0
                      ? dashboardData?.qrCode?.itemsPaid
                      : [],
                  color: theme.palette.primary.main,
                }}
                rightCard={{
                  title: "Total QR Codes Paid",
                  value: dashboardData?.qrCode?.totalPaid || "N/A",
                }}
              />
            </StackCard>
          </>
        )}
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixLimitValidatorDashboard;
