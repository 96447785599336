import { EnrollmentsPanel } from "./components/EnrollmentsPanel";
import { TransferDataPanel } from "./components/TransferDataPanel";
import { ConfirmationPanel } from "./components/ConfirmationPanel";
import { FinishPanel } from "./components/FinishPanel";

export const openFinanceSteps = [
  {
    title: "Transfer data",
    component: TransferDataPanel,
  },
  {
    title: "Select account",
    component: EnrollmentsPanel,
  },
  {
    title: "Confirmation",
    component: ConfirmationPanel,
  },
  {
    title: "Finish",
    component: FinishPanel,
  },
];
