import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasLoading from "components/molecules/Loading/Loading";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { usePaymentHistoryContext } from "./contexts/PaymentHistoryContext";
import {
  formatBank,
  formatCurrency,
  formatDateTime,
  formatDocument,
  formatStatusName,
} from "./utils/format";

export default function PaymentHistoryDetails() {
  const [details, setDetails] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pactualId = queryParams.get("pactualId");

  const {
    banksData,
    paymentHistoryData,
    loadingBanks,
    loadingPaymentHistory,
    loadingLoggedUserCpf,
  } = usePaymentHistoryContext();

  const navigateToList = () => {
    history.push("/openFinance/payment-history");
  };

  useEffect(() => {
    if (paymentHistoryData?.length) {
      const foundPayment = paymentHistoryData.find(
        (item) => item.payment?.pactualId === pactualId
      );
      const consent = foundPayment?.consent;
      const payment = foundPayment?.payment;
      setDetails({
        creditorName: {
          label: "Receiver",
          value: consent?.body?.data?.creditor?.name,
        },
        bank: {
          label: "Bank",
          value: formatBank(payment?.body?.authorizationServerId, banksData),
        },
        creditorDocument: {
          label: "Document",
          value: formatDocument(consent?.body?.data?.creditor?.cpfCnpj),
        },
        amount: {
          label: "Amount",
          value: formatCurrency(payment?.body?.data?.payment?.amount),
        },
        creditorBranch: {
          label: "Branch",
          value: payment?.body?.data?.creditorAccount?.issuer,
        },
        creditorAccountNumber: {
          label: "Account Number",
          value: payment?.body?.data?.creditorAccount?.number,
        },
        status: {
          label: "Status",
          value: formatStatusName(payment?.body?.data?.status),
        },
        transactionDate: {
          label: "Transaction Date",
          value: formatDateTime(
            payment?.body?.data?.statusUpdateDateTime ||
              payment?.body?.data?.creationDateTime
          ),
        },
      });
    } else {
      setDetails(null);
    }
  }, [paymentHistoryData]);

  if (loadingBanks || loadingPaymentHistory || loadingLoggedUserCpf) {
    return <BaasLoading />;
  }

  return (
    <BaasFlex width="100%" flexDirection="column">
      <BaasPageHeader>Open Finance</BaasPageHeader>
      <BaasContentGrid>
        <BaasGrid pb={2} pt={2}>
          <StackCard>
            <BaasText variant="h6" mb={2}>
              Payment Details
            </BaasText>
            {!details && <BaasText>There are no data available.</BaasText>}
            {details && (
              <BaasGrid container p={1} spacing={3}>
                {Object.keys(details).map((key) => (
                  <BaasGrid key={key} item xs={12} sm={6}>
                    <BaasInfoDisplay
                      label={details[key].label}
                      value={details[key].value || "-"}
                    />
                  </BaasGrid>
                ))}
              </BaasGrid>
            )}
          </StackCard>
          <BaasGrid mt={2} item xs={12} sm={6}>
            <BaasButton variant="outlined" onClick={navigateToList}>
              Go Back
            </BaasButton>
          </BaasGrid>
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
}
