import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import moment from "moment";

export default function PixLimitValidatorImpactsList({
  data,
  loading,
  setSelectedItem,
  loadMore,
  pagination,
}) {
  const theme = useTheme();
  const onViewClick = (item) => {
    setSelectedItem({
      endToEnd: item.endToEndId,
      txId: item.transactionIdentification,
    });
  };

  if (loading) {
    return (
      <>
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
      </>
    );
  }

  return (
    <BaasGrid style={{ marginTop: "16px" }}>
      <DinamicTable
        loading={loading}
        canDownloadCsv={data?.length > 0}
        data={data}
        disableRowHover={true}
        defaultSort={{
          key: "pactualId",
          orientation: "desc",
        }}
        tableSchema={[
          {
            header: "Created At (UTC)",
            key: "transactionTime",
            formatValue: (value) => {
              const val = value
                ? moment(value).add(3, "hours").format(
                  "YYYY-MM-DD HH:mm:ss"
                )
                : "N/A";
              return val;
            }
          },
          {
            header: "Entity",
            key: "entity",
            sort: true,
          },
          {
            header: "Transaction Id",
            key: "transactionIdentification",
            tooltip: true,
            style: {
              maxWidth: "320px",
              overflowWrap: "anywhere",
            },
          },
          {
            header: "End to End Id",
            key: "endToEndId",
            tooltip: true,
            style: {
              maxWidth: "320px",
              overflowWrap: "anywhere",
            },
          },
          {
            header: "Pactual Id",
            key: "pactualId",
          },
          {
            header: "Amount",
            key: "amount",
            sort: true,
            formatValue: (value) =>
              value ? `R$ ${Number(value).toFixed(2)}` : "N/A",
          },
          {
            header: "Status",
            key: "status",
            sort: true,
          },
        ]}
        extraActions={[
          {
            onClick: (item) => onViewClick(item),
            icon: InfoIcon,
            tooltip: "More info",
          },
        ]}
      />
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        style={{ marginTop: "8px" }}
      >
        <Button
          variant="outlined"
          disabled={!Boolean(pagination?.hasMoreElements)}
          onClick={() => loadMore()}
        >
          Load more results
        </Button>
      </Box>
    </BaasGrid>
  );
}
