export function formatBank(authorizationServerId, banksData) {
  if (!authorizationServerId || !banksData) return null;
  const foundBank = banksData.find(
    (bank) => bank.authorizationServerId === authorizationServerId
  );
  return foundBank?.customerFriendlyName;
}

export function formatDateTime(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleString("en-US", { timeZone: "UTC" });
}

export function formatDocument(input) {
  const value = input?.toString().replace(/\D/g, "") || "";
  switch (value.length) {
    case 11:
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    case 14:
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    default:
      return value;
  }
}

export function formatStatusColor(status) {
  switch (status) {
    case "RCVD":
    case "ACCP":
    case "ACPD":
    case "ACSC":
      return "#66BB6A";
    case "CANC":
    case "RJCT":
      return "#DE545E";
    case "PDNG":
    case "SCHD":
      return "#FFA726";
    default:
      return "#42A5F5";
  }
}

export function formatStatusName(status) {
  switch (status) {
    case "RCVD":
      return "Received";
    case "CANC":
      return "Cancelled";
    case "ACCP":
      return "Accepted Customer Profile";
    case "ACPD":
      return "Accepted Clearing Processed";
    case "RJCT":
      return "Rejected";
    case "ACSC":
      return "Accepted Settlement Completed Debtor Account";
    case "PDNG":
      return "Pending";
    case "SCHD":
      return "Scheduled";
    default:
      return "N/A";
  }
}

export function formatCurrency(amount) {
  const currentAmount = amount == null ? undefined : Number(amount) / 100;
  if (!isNaN(currentAmount)) {
    return currentAmount.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }
  return null;
}
