import { baasApiCall } from "services/apiCall";

export const queryGetEnrollmentsByCpf = (params) => {
    return baasApiCall("GET", `/bk/mock/enrollment/logged-user-identification/${params.taxId}`)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const queryGetBankOrganizations = () => {
    return baasApiCall("GET", `/bk/mock/enrollment/organizations`)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const mutationEnrollment = (payload) => {
    return baasApiCall("POST", `/bk/mock/enrollment`, payload)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const queryGetEnrollmentsByPactualId = (params) => {
    return baasApiCall("GET", `/bk/mock/enrollment/${params.pactualId}`)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const mutationFidoConsentPixKey = (payload) => {
    return baasApiCall("POST", `/bk/mock/enrollment/${payload.pactualId}/consent/pix-key`, payload)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const mutationFidoConsentAuthorize = (payload) => {
    return baasApiCall("POST", `/bk/mock/enrollment/${payload.pactualId}/consent/${payload.consentPactualId}/authorize`, payload)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const mutationFidoRegister = (payload, pactualId) => {
    return baasApiCall("POST", `/bk/mock/enrollment/${pactualId}/registration`, payload)
        .then((data) => data.json())
        .catch((error) => error.json());
};

export const queryPoolingPayment = (params) => {
    return baasApiCall("GET", `/bk/mock/enrollment/${params.pactualId}/consent/${params.consentPactualId}/payments`)
        .then((data) => data.json())
        .catch((error) => error.json());
};
export const queryGetLoggedUserCpf = () => {
    return baasApiCall("GET", `/bk/mock/enrollment/credential`)
        .then((data) => data.json())
        .catch((error) => error.json());
}

export const mutationSendLog = (payload) => {
    return baasApiCall("POST", `/bk/mock/enrollment/log`, payload)
        .then((data) => data.json())
        .catch((error) => error.json());
}