import { Box, Button, TextField } from "@mui/material";
import { mutationQrCodePayment } from "api/baasTester/qrPayment-api";
import { consentValidateAuthorize } from "api/openFinance/consentApproval-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { consentValidateReject } from "api/openFinance/consentApproval-api";

const ConsentApproval = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [consentId, setConsentId] = useState(id || "");
    const { mutate: mutateAuthorize, isSubmitting: isSubmittingAuthorize } = useCustomMutation(consentValidateAuthorize);
    const { mutate: mutateReject, isSubmitting: isSubmittingReject } = useCustomMutation(consentValidateReject);

    const handleChange = (value) => {
        setConsentId(value);
    };

    const onSubmitAuthorize = useCallback(() => {
        mutateAuthorize(
            {
                consentId: consentId ? consentId.replaceAll(":", "%3A") : "",
            },
            (data) => {
                setConsentId("");
                if (data?.body?.body?.url) {
                    window.location.replace(data?.body?.body?.url)
                }
            },
            (error) => {
                return toast.error(error.message);
            }
        );
    }, [consentId, mutateAuthorize]);

    const onSubmitReject = useCallback(() => {
        mutateReject(
            {
                consentId: consentId ? consentId.replaceAll(":", "%3A") : "",
            },
            (data) => {
                setConsentId("");
                if (data?.body?.body?.url) {
                    window.location.replace(data?.body?.body?.url)
                }
            },
            (error) => {
                return toast.error(error.message);
            }
        );
    }, [consentId, mutateReject]);

    const checkIsDisabled = () => {
        if (!consentId) {
            return true;
        }
    };

    return (
        <BaasFlex flex={1} flexDirection="column">
            <>
                <BaasPageHeader>Open Finance</BaasPageHeader>
                <BaasGrid
                    item
                    spacing={1}
                    style={{ padding: "16px", marginBottom: "16px" }}
                >
                    <BaasRow>
                        <BaasText variant="h6">Consent Approval</BaasText>
                    </BaasRow>
                    <BaasText variant="body2">
                        Approve or Reject a consent.
                    </BaasText>
                </BaasGrid>
                <BaasContentGrid spacing={4}>
                    <StackCard>
                        <BaasGrid item>
                            <TextField
                                autoFocus
                                name="consentId"
                                margin="dense"
                                label="Consent Id"
                                type="text"
                                multiline
                                value={consentId}
                                fullWidth
                                variant="standard"
                                onChange={(e) => handleChange(e.target.value)}
                            />
                        </BaasGrid>
                        <Box display="flex" width="100%" marginTop="16px" justifyContent="space-between">
                            <Button
                                loading={false}
                                disabled={checkIsDisabled() || isSubmittingReject}
                                variant="outlined"
                                color="error"
                                style={{ minWidth: "120px" }}
                                onClick={onSubmitReject}
                                type="submit"
                            >
                                {isSubmittingReject ? "Processing" : "Reject"}
                            </Button>
                            <Button
                                loading={false}
                                disabled={checkIsDisabled() || isSubmittingAuthorize}
                                variant="contained"
                                style={{ minWidth: "120px" }}
                                type="submit"
                                onClick={onSubmitAuthorize}

                            >
                                {isSubmittingAuthorize ? "Processing" : "Approve"}
                            </Button>
                        </Box>

                    </StackCard>
                </BaasContentGrid>
            </>
        </BaasFlex>
    );
};

export default ConsentApproval;
