export function formatBank(authorizationServerId, banksData) {
  if (!authorizationServerId || !banksData) return null;
  const foundBank = banksData.find(
    (bank) => bank.authorizationServerId === authorizationServerId
  );
  return foundBank?.customerFriendlyName;
}

export function formatDateTime(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleString("en-US", { timeZone: "UTC" });
}

export function formatStatusColor(status) {
  if (!status) return null;
  return status === "AUTHORISED" ? "#66BB6A" : "#DE545E";
}
