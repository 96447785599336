import { baasApiCall } from "services/apiCall";

export const queryGetPaymentHistory = ({ cpf }) => {
  return baasApiCall(
    "GET",
    `/bk/mock/enrollment/logged-user-identification/${cpf}/payments`
  )
    .then((data) => data.json())
    .catch((error) => error);
};
