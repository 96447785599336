import { Box } from "@mui/material";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasLoading from "components/molecules/Loading/Loading";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import AccountCard from "./components/AccountCard";
import { useEnrolledAccountsContext } from "./contexts/EnrolledAccountsContext";

export default function EnrolledAccountsList() {
  const {
    enrolledAccountsData,
    loadingBanks,
    loadingEnrolledAccounts,
    loadingLoggedUserCpf,
  } = useEnrolledAccountsContext();

  if (loadingBanks || loadingEnrolledAccounts || loadingLoggedUserCpf) {
    return <BaasLoading />;
  }

  return (
    <BaasFlex width="100%" flexDirection="column">
      <BaasPageHeader>Open Finance</BaasPageHeader>
      <BaasContentGrid>
        <BaasGrid p="16px 0px" xs={12}>
          <Box display="flex" alignItems="center">
            <BaasText variant="h6">Enrolled Accounts</BaasText>
          </Box>
        </BaasGrid>
        <Box display="flex" flexDirection="column" gap="16px">
          {!enrolledAccountsData?.length && (
            <BaasText>There are no data available.</BaasText>
          )}
          {enrolledAccountsData?.map((account) => (
            <AccountCard account={account} key={account.pactualId} />
          ))}
        </Box>
      </BaasContentGrid>
    </BaasFlex>
  );
}
