import { Box, Checkbox, Skeleton } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  mutationAnswerQuestion,
  queryClientQuestionsComplete,
} from "api/baasTester/baasTester-api";
import { ReactComponent as CompleteIcon } from "assets/icons/BaasTesterLogos/Complete.svg";
import { ReactComponent as CompletionIcon } from "assets/icons/BaasTesterLogos/Completion.svg";
import { ReactComponent as TotalIcon } from "assets/icons/BaasTesterLogos/Total.svg";
import AutocompleteDefault from "components/atoms/Autocomplete/AutocompleteDefault";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasTesterQuestionsList from "components/organism/BaasTesterQuestionsList/BaasTesterQuestionsList";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import React, { useCallback, useEffect, useState } from "react";
import StackCard from "components/atoms/StackCard/StackCard";

const BaasTester = () => {
  const theme = useTheme();
  const [refetch, setRefetch] = useState(false);
  const [filterNotAnswered, setFilterNotAnswered] = useState(false);
  const handleRefetchQuestions = () => {
    setTimeout(() => {
      setRefetch(!refetch);
    }, 300);
  };

  const {
    fetch: loadQuestions,
    loading: loadingQuestions,
    data,
  } = useCustomQuery(queryClientQuestionsComplete);

  const questionsData = (data || []).filter((item) => {
    return item.status !== "WAIVED";
  });
  const [selectedCategory, setSelectedCategory] = useState();

  const { mutate, isSubmitting } = useCustomMutation(mutationAnswerQuestion);

  const onSubmitAnswer = useCallback(
    (answer, questionId) => {
      const regex = /^['"]|['"]$/g;
      const answerRegex = `${answer.replace(regex, '"')}`;
      mutate(
        {
          questionId,
          answer: answerRegex,
        },
        () => handleRefetchQuestions()
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch]
  );

  useEffect(() => {
    loadQuestions({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const getGroupsOptions = () => {
    let options = [];
    questionsData.forEach((item) => {
      if (!options.some((opt) => opt === item.question?.group)) {
        options.push(item.question?.group);
      }
    });
    options.push("All Groups");
    return options.sort((a, b) => a.localeCompare(b));
  };

  const getTotalCompleted = (categories) => {
    if (categories && categories.length > 0) {
      const totalCompleted = categories.reduce((cont, obj) => {
        if (obj.completed === true) {
          return cont + 1;
        } else {
          return cont;
        }
      }, 0);
      return totalCompleted;
    }
    return 0;
  };

  const getScore = () => {
    let answered = 0;
    let totalQuestions = (questionsData || []).filter((question) => {
      return question.status === "OBRIGATORY" || question.status === "OPTIONAL"
    }).length || 0;
    let totalRequiredQuestions = (questionsData || []).filter((question) => {
      return question.status === "OBRIGATORY"
    }).length || 0;
    let percentage = 0;
    let categories = [];
    if (totalQuestions > 0) {
      questionsData.forEach((item) => {
        const group = item.question?.group;

        if (group) {
          if (!categories.some((cat) => cat.group === group)) {
            categories.push({
              group: group,
              total: 0,
              answered: 0,
            });
          }
        }
        const index = categories.findIndex((cat) => cat.group === group);
        if (item.answer?.value) {
          if (index !== -1) {
            categories[index].total += 1;
            categories[index].answered += 1;
            categories[index].completed = Boolean(
              categories[index].total === categories[index].answered
            );
          }
          answered = answered + 1;
        } else {
          if (index !== -1) {
            categories[index].total += 1;
            categories[index].completed = Boolean(
              categories[index].total === categories[index].answered
            );
          }
        }
      });
      percentage = (answered / totalQuestions) * 100;
    }

    return [
      {
        icon: CompleteIcon,
        label: "Complete categories",
        value: `${getTotalCompleted(categories)}/${categories.length}`,
      },
      {
        icon: TotalIcon,
        label: "Total required tests",
        value: `${answered} / ${totalRequiredQuestions}`,
      },
      {
        icon: CompletionIcon,
        label: "Completion",
        value: `${percentage.toFixed(0)}%`,
      },
    ];
  };

  const handleFilterQuestions = () => {
    let questionsListToFilter = filterNotAnswered
      ? questionsData.filter((item) => {
        return item.answer === undefined;
      })
      : questionsData;
    if (selectedCategory) {
      let filteredQuestions = questionsListToFilter.filter((item) => {
        return item.question?.group === selectedCategory;
      });
      return filteredQuestions;
    } else {
      return questionsListToFilter;
    }
  };

  const getTotalAmount = () => {
    return {
      waived: (questionsData || []).filter((question) => {
        return question.status === "WAIVED"
      }).length || 0,
      optional: (questionsData || []).filter((question) => {
        return question.status === "OPTIONAL"
      }).length || 0,
      obrigatory: (questionsData || []).filter((question) => {
        return question.status === "OBRIGATORY"
      }).length || 0,
    }
  }

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>BaaS Tester</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Test Control Suite</BaasText>
          </BaasRow>
          <BaasText variant="body2">
            This session refers to the BaaS Tester and it’s many uses.
          </BaasText>
        </BaasGrid>
        <StackCard sx={{ marginTop: 2 }}>
          <Box p={1} mt={2}>
            <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
              General Score
            </BaasText>
            <Box display="flex" justifyContent="space-between">
              {getScore().map((item) => (
                <Box display="flex" mt={3} key={item.label}>
                  <Box display="flex" flexDirection="column" mr={3}>
                    <Box display="flex" >
                      <item.icon />
                      <BaasText color="text.secondary" ml={1} style={{ textTransform: "capitalize" }}>
                        {item.label}
                      </BaasText>
                    </Box>
                    {loadingQuestions ? (
                      <Skeleton />
                    ) : (
                      <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
                        {item.value}
                      </BaasText>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box display="flex" mt={2} marginLeft={"-2px"}>
              <BaasText color="text.secondary" mr={2}>
                🔴 Required Tests: {getTotalAmount().obrigatory}
              </BaasText>
              <BaasText color="text.secondary" mr={2}>
                🔵 Optional Tests: {getTotalAmount().optional}
              </BaasText>
              {/* <BaasText color="text.secondary">
                🔵 Waived Questions: {getTotalAmount().waived}
              </BaasText> */}
            </Box>
          </Box>
        </StackCard>
        <StackCard sx={{ marginTop: 3 }}>
          <AutocompleteDefault
            name="testCategory"
            label="Select test group"
            isLoading={false}
            inputVariant="standard"
            onClear={() => setSelectedCategory(undefined)}
            onSelect={(value) =>
              value === "All Groups"
                ? setSelectedCategory(undefined)
                : setSelectedCategory(value)
            }
            options={getGroupsOptions()}
            getOptionLabel={(option) => option.toUpperCase()}
          />
          <Box
            display="flex"
            alignItems="center"
            marginTop="20px"
            marginLeft="-8px"
          >
            <Checkbox
              defaultChecked={filterNotAnswered}
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setFilterNotAnswered(!filterNotAnswered);
              }}
            />
            <BaasText variant="body1" color="textSecondary">
              <b>Display only not answered questions</b>
            </BaasText>
          </Box>
        </StackCard>

        <Box mt={3}>
          <BaasTesterQuestionsList
            selectedCategory={selectedCategory}
            data={handleFilterQuestions()}
            loading={loadingQuestions}
            submitingAnswer={isSubmitting}
            onAnswer={onSubmitAnswer}
          />
        </Box>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default BaasTester;
